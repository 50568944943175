import './dialog'

tag cancel-subscription-modal < app-dialog
	css 1w:max(40%,340px)
	css 1t:5vh
	css button ws:normal

	get subscription
		store.subscription
	get processor
		subscription.processor


	<self.modal>
		<a$cover href="#">
		<$box>
			<header[mb:3]> <h3[ta:center fs:lg]> "Cancel Subscription"
			<h3[p:2]> "Do you really want to stop your subscription and loose access?"
			<p[p:2]> "We can not guarantee that you will be able to re-subscribe at the same low price point, as Scrimba will gradually become more expensive the next year for new subscribers."
			<p[p:2]> "If you still want to cancel, we ask you for 30 seconds of your time we would really like to know why you are cancelling"
			<form[d:flex fld:column] method='POST' action="/{processor}/subscription/cancel">
				<textarea[p:2 my:4 resize:none bd: 1px solid $lemony] name='reason' placeholder="What made you want to cancel? E.g. Time, Cost, Difficulty, Bugs">
				<input type='hidden' name='sub_id' value=subscription.id>
				<[d:hflex ]>
					<button.button.sm @click.prevent=close> "No, I want to keep learning!"
					<button[fw:400].button.text.sm type='submit'> "Yes, please cancel my subscription"

