import Pricing from '../shared/pricing/pricing'
import './dialog'
import {Zendesk} from '../services/zendesk'

css h2 font-style:italic
css h1,h2 ta:center fw:600

tag usps
	prop hide = false
	css .usps
		li py:3 ta:center
		h4 mb:0 font-style:italic fs@ltlg:1.25rem
		p c:gray7 fs@!ltlg:1rem lh@!ltlg:1.2

	css @!900
		.usps
			h2 mb:0

	css @!700
		.usps
			li py:0
			h2 fs:md/1.1


	<self>
		<.section.copy>					
			<ul.usps>
				if hide
					<li>
						<h4.bold> "Access ALL our courses"
						<p> "Take all our Courses & Career Paths"
					<li>
						<h4.bold> "Get Course Certifications"
						<p> "Certificates to help your job search"
					<li>
						<h4.bold> "Exclusive Pro members chat"
						<p> "Meet fellow students and share knowledge"
				elif store.user
					<li>
						<h4.bold> "30-day money-back guarantee"
						<p> "No questions asked, full refund within 24 hours"
					<li>
						<h4.bold> "Keep your discount"
						<p> "Keep your discount for as long as you subscribe"
					<li>
						<h4.bold> "Cancel whenever"
						<p> "You can cancel your subscription at any time"
					<li>
						<h4.bold> "Access ALL our courses"
						<p> "Take all our Courses & Career Paths"
					<li>
						<h4.bold> "Get Course Certifications"
						<p> "Certificates to help your job search"
				else
					<li>
						<h4.bold> "Money Back Guarantee"
						<p> "Full 30-day guarantee, no questions asked."
					<li>
						<h4.bold> "Keep Discount For Life"
						<p> "Your discount stays for as long as you subscribe."
					<li>
						<h4.bold> "Cancel Whenever"
						<p> "No lock-in period. Cancel whenever you want."
					<li>
						<h4.bold> "Access ALL Courses"
						<p> "You get access to every single course on Scrimba."

tag join-modal < app-dialog
	css self.busy $box scale:0.95
		a,button pe:none
	css $box p:0 max-width:80rem w:calc(100% - 2em) @lg:65%
	css self header
		pos:absolute w:100% t:0 h:100px ta:center zi:4 fs:3xl fw:bold d:none @400:vflex ja:center
		h3 fw:bold ta:center rotate:-1deg ls:-1px mt:8 c:orange8

	css .section w:100% d:vflex ja:center pos:relative fl: 1 1 auto 
		p:10
		pt:10 @400:150px
		pb:10 @400:50px
		@after content:" " d:block max-height:120px fl:1 1 auto

	css .payment d:grid gtc:1fr @sm:1fr 1fr @lg:1fr 1fr 1fr 1fr grid-gap:15px mt:8 tween: all 0.2s linear
	css .payment.methods gtc:1fr @sm:1fr 1fr

	css .busy-cover pos:absolute inset:0 d:none bg:inherit o:0.7
	css .busy + .busy-cover d:block

	css .mark-yellow pos:relative zi:2 c:orange8 fs:lg py:2px px:4px 
		@before pos:absolute inset:0 my:1 bg:yellow4 content:" " d:block zi:-1 rotate:-2deg scale-x:1.1


	css .table
		.row d:hflex lh:1.8 fs:lg
		.row .desc fl:1
		.neg c:orange8
		.totals,.coupon
			bdt:1px solid gray6/30 fw:700 mt:2 pt:2
		.coupon .desc c:orange8

	css .button-group
		d:hgrid bg:blue2/10 rd:md bxs:inset 0 0 0 1px blue5
		.item d:hflex ja:center p:3 rd:inherit fw:bold c:blue7
		.checked bg:blue5 c:white

	css .plan-options
		d:hflex rd:lg bg:gray3 # bxs:inset 0 0 0 1px gray4 
		.option
			w:30% fl:1 ta:center p:2 d:vflex ja:center rd:inherit
			bd:1px solid clear
			.name fs:xl mb:-1
			.price fs:lg
				@after
					content: "/month" o:0.5 ml:0.2 fs:xs
			&.checked bg:blue5 c:white my:-1 py:3 bc:blue7
				.name fw:bold

	css .tab-group
		d:hflex bdb:1px solid gray6/30 pos:relative
		.item d:hflex ja:center py:2 fw:bold c:gray6 mb:-1px rdt:md bdb:1px solid clear mr:4
		.checked2 bg:blue5 c:white bc:blue7
		.checked bc:blue7 c:blue7 pe:none
		@after d:block content:" " pos:absolute 

	css .field
		w:100% py:3 px:3 rd:md bd:1px solid bc:gray4 fs:md outline:none
		ff:inherit font-style:inherit fw:inherit bg:white
		@focus bxs:glow bc:blue4

	css .button
		ws:pre
		b fw:700
		&.paypal fw:400

	
	css fieldset 
		label fs:sm mb:1
		input p:2
		p:0 mb:3 mx:0

	css .foot
		d:vflex ai:flex-end mt:6
		button mb:15px

	css .card-form
		d:none

	css .hide-element
		d:none
	
	css .show-element
		d:block

	css form.busy
		o:0.5 pe:none

	css @!700
		# $box d:block ofy:auto
		.section.copy d:none

	processor = 'stripe'

	prop authFormVisible = no
	prop stripe
	prop use-usd = no
	prop selected-plan-id = default-plan
	prop hide-coupon? = no

	get default-plan do 'annual'
	get selected-plan do pricing[selected-plan-id]
	get paypal? do processor == 'paypal'
	get stripe? do processor == 'stripe'
	
	pricing = load-pricing!

	get price-type do use-usd or processor == 'paypal' ? 'usd' : 'local'
	get currency do price-type == 'usd' ? 'usd' : pricing.currency

	get coupon do selected-plan..coupon
	get selected-discount-list
		if selected-plan.coupon
			let list = selected-plan.discount.list.filter do $1 != selected-plan.coupon.title
			list.join(' + ')
		else
			selected-plan.discount.list.join(' + ')

	get header-promo-text
		if selected-plan.trial?
			"Try free for {selected-plan.duration}!"
		elif hide-coupon?
			"Nice {coupon.title} - ready to join?"
		elif selected-plan.discounted?
			<>
				"Limited discount: "
				<span[td:line-through]> selected-plan.reference.plan_text
				<span> " {selected-plan.price.plan_text} for {selected-plan.duration}!"
		else
			<>
				"Go pro for only "
				<span> " {selected-plan.price.plan} for {selected-plan.duration}!"
	
	get cid
		try
			let gaTrackers = window.ga.getAll!
			gaTrackers[0].get('clientId') if gaTrackers
		catch err
			''
	def load-pricing
		const code = new URL(window.location.href).searchParams.get('coupon')
		if code and store.me
			const cpn = store.me.coupons ? (store.me.coupons.find do $1.code == code and $1.percent == 0 and $1.hide_prices) : undefined
			hide-coupon? = !!cpn

		pricing = new Pricing
			pricing: store.me..pricing
			country: store.me..country
			coupons: store.me..coupons
			url_coupon: code
			subscription: store.subscription
			usd: use-usd

	def setup
		load-pricing!
		window.zendesk ||= new Zendesk
		use-usd = window.location.href.indexOf('use_usd') > -1 ? yes : no
		window.$once('userdata').then do
			load-pricing!
			select-best-plan!
		window.$once('stripe').then do stripe = Stripe(store.stripeKey)

		select-best-plan!
		

	def coupon-to-plan-name coupon
		const priority = ['bootcamp','bootcamp3','annual','semester','monthly']
		const plan = priority.find do coupon.plans.includes($1)
		return plan ? plan : 'annual'

	def select-best-plan
		# Best plan preference, url coupon, free trial, best coupon, annual
		return 'annual' unless store.me.coupons
		
		const url = new URL(window.location.href)
		if const code = url.searchParams.get('coupon')
			let cpn = store.me.coupons.find do $1.code == code
			return selected-plan-id = coupon-to-plan-name(cpn) if cpn

		let cpn = store.me.coupons.reduce(&, undefined) do(prev, curr)
			!prev or curr.percent < prev.percent ? curr : prev
		selected-plan-id = coupon-to-plan-name(cpn) if cpn

	def open
		super
		const page = document.getElementsByClassName('page')
		api.track('Subscriptions', 'Dialog opened', page[0].localName) if page.length > 0

	def close
		super
		unless window.location.pathname.includes('/pricing')
			window.zendesk.hide!
		authFormVisible = no

	def authenticate e
		let action = signin ? '/auth/login' : '/auth/signup'
		let params = Object.fromEntries(new FormData($form))

		let res = await api.post(action,params)
		if res
			if res.message
				imba.mount <app-snackbar data=res>

			# try to fetch data
			if res.code < 400
				await window.reloadUserData!
				if store.subscription
					return close!
				else
					window.zendesk.show!
				toggleAuthForm!

			return res

	def toggleAuthForm sum
		if authFormVisible == no and window.location.hostname == 'scrimba.com'
			let url = window.location.href.indexOf('#') > -1 ? window.location.href.split('#')[0] : window.location.href
			api.track('Subscriptions', 'GoToPayment Clicked', url)
		authFormVisible = !authFormVisible

	def set-processor _processor
		processor = _processor
		load-pricing!

	def payWithStripeCheckout
		# pass on referral only if user doesn not have affiliate so that we use the api.
		let ref = store..me..affiliate..email ? '' : window.tolt_referral 
		let params =
			product_name: selected-plan.id
			purchase_url: window.location.href.indexOf('#') > -1 ? window.location.href.split('#')[0] : window.location.href
			coupon_code: coupon ? coupon.code : no
			use_usd: use-usd
			cid: cid
			referral: ref

		let res = await api.post("/stripe/subscription/checkout", params)
		if res.message
			imba.mount <app-snackbar data=res>

		if res.code == 200
			return stripe.redirectToCheckout(sessionId: res.session)

	def payWithPayPal
		let purchase_url = window.location.href.indexOf('#') > -1 ? window.location.href.split('#')[0] : window.location.href
		if window.location.hostname == 'scrimba.com'
			api.track('Subscriptions', 'GoToPayment Clicked', purchase_url)

		let params = 
			cid: cid
			coupon_code: coupon ? coupon.code : no
			product_name: selected-plan.id
			purchase_url: purchase_url

		let res = await api.post("/paypal/subscription/create",params)
		if res.message
			imba.mount <app-snackbar data=res>

			# try to fetch data
			if res.code < 400
				await window.reloadUserData!
			# res.code < 400 ? completed(res,e) : failed(res,e)
			return res
			
		if res.code >= 400 and res.message
			imba.mount <app-snackbar data=res>
		if res..message == 'Already an active subscriber'
			let url = new URL(document.location.href)
			url.hash = ''
			window.location.href = url.href
		elif processor == 'paypal' and res..approvalLink
			window.location = res.approvalLink.href
		else
			imba.mount <app-snackbar data=res>

	def payWithCoupon
		let purchase_url = window.location.href.indexOf('#') > -1 ? window.location.href.split('#')[0] : window.location.href
		if window.location.hostname == 'scrimba.com'
			api.track('Subscriptions', 'GoToPayment Clicked', purchase_url)

		let params =
			product_name: selected-plan.id
			purchase_url: purchase_url
			coupon_code: coupon ? coupon.code : no
			cid: cid

		let res = await api.post("/coupon/subscribe",params)
		if res.message
			imba.mount <app-snackbar data=res>

			# try to fetch data
			if res.code < 400
				await window.reloadUserData!
			# res.code < 400 ? completed(res,e) : failed(res,e)
			window.location = "/dashboard#pro-success"

		if res.code >= 400 and res.message
			imba.mount <app-snackbar data=res>
		if res..message == 'Already an active subscriber'
			let url = new URL(document.location.href)
			url.hash = ''
			window.location.href = url.href
		elif processor == 'paypal' and res..approvalLink
			window.location = res.approvalLink.href
		else
			imba.mount <app-snackbar data=res>
	
	def body
		<div[d:flex]>
			<header>
				<h3[d@subscriber:none]> header-promo-text

			<.section.copy>
				<usps hide=hide-coupon?>
			
			<.section[bg:gray1]>
				<div[d:none @subscriber:vflex]>
					<h2> "Welcome back!"

				<div[d@subscriber:none m:auto as:stretch w:100%]>
					<.hide-element=(authFormVisible)>
						<h4.bold[ta:left mb:0]> "Choose your plan"
						<select.plans.field[mb:2] bind=selected-plan-id> for item in pricing.available-plans
							<option value=item.id> 
								"{item.name} - {item.price.plan_text} {item.interval_count > 1 ? "(Per month: {item.price.monthly_text})" : ""}"

						if selected-plan.interval_count == 'freetrial'
							<.receipt.table[mt:2 mb:2]>
								<.row>	
									<.desc> "7 day free trial"
									<.sum> selected-plan.price.monthly_text
								<.row.totals>
									<.desc>
										"Total"
									<.sum> selected-plan.price.monthly_text
							<.foot>
								<button[mx:0 d:none d@guest:block].button.md @click.prevent.throttle.flag-busy=toggleAuthForm>
									<span> "Start {<b> "your free trial"}"
								<button[mx:0 d@guest:none].button.md @click.prevent.throttle.flag-busy=payWithCoupon>
									<span> "Start {<b> "your free trial"}"
						elif hide-coupon?
							<.receipt.table[mt:2 mb:2]>
								<.row>	
									<.desc> "Coupon purchase"
									<.sum> selected-plan.price.monthly_text
								<.row.totals>
									<.desc>
										"Total"
									<.sum> selected-plan.price.monthly_text
							<.foot>
								<button[mx:0 d:none d@guest:block].button.md @click.prevent.throttle.flag-busy=toggleAuthForm>
									<span> "Start {<b> "your membership"}"
								<button[mx:0 d@guest:none].button.md @click.prevent.throttle.flag-busy=payWithCoupon>
									<span> "Start {<b> "your membership"}"
						else

							<.receipt.table[mt:2 mb:2]>
								<p.small[c:$night]> "We only support USD ($) with paypal" if paypal? and store.me.pricing.currency != 'USD'
								<.row>	
									<.desc> "{selected-plan.interval_count} month{selected-plan.interval_count == 1 ? '' : 's'} x {selected-plan.reference.monthly_text}"
									<.sum> selected-plan.reference.plan_text
								<.row .neg[fw:700]>
									<.desc[d:vflex]>
										<span> "Your discount"
									<.sum> "-" + selected-plan.discount.value_text
								
								<p.small.neg[ my:0 fw:thin]> "Coupon: { <strong> coupon.title }" if selected-plan.coupon
								<p.small.neg[ my:0 fw:thin]> selected-discount-list

								<.row.totals>
									<.desc>
										"Total"
									if selected-plan.discounted?
										<span.save.mark-yellow[mr:4]> "{selected-plan.discount.percent_text}% off"
									<.sum> selected-plan.price.plan_text
							<.foot>
								if coupon..percent == 0
									<button[mx:0 d:none d@guest:block].button.md @click.prevent.throttle.flag-busy=toggleAuthForm>
										<span> "Redeem your gift"

									<button[mx:0 d@guest:none].button.md @click.prevent.throttle.flag-busy=payWithCoupon>
										<span> "Redeem your gift"
								elif paypal? # if guest we need to login first
									<button[mx:0 d:none d@guest:block].button.md @click.prevent.throttle.flag-busy=toggleAuthForm>
										<span> "Continue with PayPal"
									<button[mx:0 d@guest:none].button.md @click.prevent.throttle.flag-busy=payWithPayPal>
										<span> "Continue with PayPal"
								elif stripe? and store.uid
									<button[mx:0].button.md @click.prevent.throttle.flag-busy=payWithStripeCheckout(selected-plan.plan)>
										<b> "Continue with payment"
								else
									<button[mx:0].button.md @click.prevent.throttle.flag-busy=toggleAuthForm(selected-plan.plan)>
										<b> "Continue with payment"
								<p.small[c:gray7]> 
									"Payment problems or questions? Follow "
									<a.link.small target="blank" href="https://scrimba.com/links/payment-troubleshoot"> "this guide"
									" or "
									<a.link.small target="blank" @click=window.zendesk.open> "talk to us"

					<.hide-element=!(authFormVisible)>
						<form$form[w:300px mx:auto mt:4 d:none d@guest:block] action='/auth/signup' @submit.prevent.throttle.flag-busy=authenticate>
							<h4.bold[font-style:italic ta:center]> signin ? "Log in" : "Create your account" 
							<div[d:vgrid g:2]>
								<input type='email' name='email' placeholder='Email' autocomplete='email' required>
								<input type='password' name='password' autocomplete="new-password" placeholder='Password' required>
								<button.button.neutral[ w:100%] type='submit'> signin ? "Log in" : "Create Account"
								<.group-head[my:1 c:gray6]> <p.small> "or"
								<a.button[w:100%] href="/auth/github?from={store.url.pathname}%23join"> "Sign {signin ? "in" : "up"} with GitHub"
								<p.small[mt:4 c:red8]>
									"Having issues signing in? "
									<a.small href="https://www.google.com/search?q=clear+cookies" target="_blank"> "Clearing your cookies will resolve it"
							if signin
								<p.small[mt:3 c:gray7]>
									"Don't have an account? "
									<a[ml:1].link.small @click=(signin = no)> "Sign up"
							else
								<p.small[mt:3 c:gray7]>
									"Already have an account? "
									<a[ml:1].link.small @click=(signin = yes)> "Log in"
							<div.busy-cover>

			<div[l:50% w:100% x:-50% b:0 pos:absolute h:10% px:2 d:none @sm:grid ac:center]>
				if store.user
					<p.small[ta:center]> "Buying for a group? "
						<a[cursor:pointer].small href="mailto:help@scrimba.com?subject=Scrimba%20Group%20Deal%20%F0%9F%92%B0"> "Chat to us for a deal."

						
	def render
		return if pricing.loading?
		super()
