import './dialog'
import {Zendesk} from '../services/zendesk'
import Pricing from '../shared/pricing/pricing'

css h2 font-style:italic
css h1,h2 ta:center fw:600

tag bootcamp-usps
	css .usps
		li py:3 ta:center
		h4 mb:0 font-style:italic fs@ltlg:1.25rem
		p c:gray7 fs@!ltlg:1rem lh@!ltlg:1.2

	css @!900
		.usps
			h2 mb:0

	css @!700
		.usps
			li py:0
			h2 fs:md/1.1

	<self>
		<.section.copy>					
			<ul.usps>
				<li>
					<h4.bold> "Collaborate with a small group"
					<p> "Boost learning outcomes with a peer study group."
				<li>
					<h4.bold> "In-depth code reviews"
					<p> "Get reviews and advice from experienced coders."
				<li>
					<h4.bold> "Weekly teacher-led sessions"
					<p> "Set goals, discuss challenges, and stay on track."
				<li>
					<h4.bold> "Build your network"
					<p> "Connect with a network of like-minded students."
				<li>
					<h4.bold> "14-day money-back guarantee"
					<p> "If the Bootcamp isn't for you, we'll refund you, no questions asked."

tag bootcamp-modal < app-dialog
	prop paymentFormVisible = no
	prop stripe
	prop selected-plan
	prop use-usd = no
	prop authFormVisible = no
	prop signin = no


	css self.busy $box scale:0.95
		a,button pe:none

	css $box p:0 max-width:80rem w:calc(100% - 2rem) @lg:952px
	
	css self header
		pos:absolute w:100% t:0 h:100px ta:center zi:4 fs:3xl fw:bold d:none @400:vflex ja:center
		h3 fw:bold ta:center rotate:-1deg ls:-1px mt:8 c:orange8

	css .section w:100% d:vflex ja:center pos:relative fl: 1 1 auto 
		p:4 @400:10
		pt:10 @400:150px
		pb:10 @400:50px
		@after content:" " d:block max-height:120px fl:1 1 auto

	css .payment d:grid gtc:1fr @sm:1fr 1fr @lg:1fr 1fr 1fr 1fr grid-gap:15px mt:8 tween: all 0.2s linear
	css .payment.methods gtc:1fr @sm:1fr 1fr

	css .busy-cover pos:absolute inset:0 d:none bg:inherit o:0.7
	css .busy + .busy-cover d:block

	css .mark-yellow pos:relative zi:2 c:orange8 fs:lg py:2px px:4px 
		@before pos:absolute inset:0 my:1 bg:yellow4 content:" " d:block zi:-1 rotate:-2deg scale-x:1.1

	css .table
		.row d:hflex lh:1.8 fs:lg
		.row .desc fl:1
		.neg c:orange8
		.totals,.coupon
			bdt:1px solid gray6/30 fw:700 mt:2 pt:2
		.coupon .desc c:orange8

	css .button-group
		d:hgrid bg:blue2/10 rd:md bxs:inset 0 0 0 1px blue5
		.item d:hflex ja:center p:3 rd:inherit fw:bold c:blue7
		.checked bg:blue5 c:white

	css .plan-options
		d:hflex rd:lg bg:gray3 # bxs:inset 0 0 0 1px gray4 
		.option
			w:30% fl:1 ta:center p:2 d:vflex ja:center rd:inherit
			bd:1px solid clear
			.name fs:xl mb:-1
			.price fs:lg
				@after
					content: "/month" o:0.5 ml:0.2 fs:xs
			&.checked bg:blue5 c:white my:-1 py:3 bc:blue7
				.name fw:bold

	css .tab-group
		d:hflex bdb:1px solid gray6/30 pos:relative
		.item d:hflex ja:center py:2 fw:bold c:gray6 mb:-1px rdt:md bdb:1px solid clear mr:4
		.checked2 bg:blue5 c:white bc:blue7
		.checked bc:blue7 c:blue7 pe:none
		@after d:block content:" " pos:absolute 

	css .field
		w:100% py:3 px:3 rd:md bd:1px solid bc:gray4 fs:md outline:none
		ff:inherit font-style:inherit fw:inherit bg:white
		@focus bxs:glow bc:blue4

	css .button
		ws:pre
		b fw:700
	
	css fieldset 
		label fs:sm mb:1
		input p:2
		p:0 mb:3 mx:0

	css .foot
		d:vflex ai:flex-end mt:6
		button mb:15px

	css .card-form
		d:none

	css .hide-element
		d:none
	
	css .show-element
		d:block

	css form.busy
		o:0.5 pe:none

	css @!700
		# $box d:block ofy:auto
		.section.copy d:none

	get cid
		try
			let gaTrackers = window.ga.getAll!
			gaTrackers[0].get('clientId') if gaTrackers
		catch err
			''

	pricing = null

	get bootcamp_monthly do pricing.bootcamp
	get bootcamp do pricing.bootcamp3
	get pro? do store.subscription and store.subscription.type == 'pro'
	get rate do use-usd ? 1 : (pricing.rate or pricing..pricing..rate)
	get currency do use-usd ? 'usd' : pricing.currency

	# get bootcamp-price do use-usd ? Math.min(bootcamp.price_usd, 199) : bootcamp.price
	get bootcamp-price do use-usd ? bootcamp.price_usd : bootcamp.price
	get pro-reduction do (store..subscription..price or 0) * rate * 3

	def setup
		use-usd = window.location.href.indexOf('use_usd') > -1 ? yes : no
		window.zendesk ||= new Zendesk
		load-pricing!
		window.$once('userdata').then do load-pricing!
		window.$once('stripe').then do stripe = Stripe(store.stripeKey)

	def open
		super
		const page = document.getElementsByClassName('page')
		api.track('Subscriptions', 'Bootcamp dialog opened', page[0].localName) if page.length > 0

	def close
		super
		unless window.location.pathname.includes('/pricing')
			window.zendesk.hide!
		paymentFormVisible = no

	def authenticate e
		let action = signin ? '/auth/login' : '/auth/signup'
		let params = Object.fromEntries(new FormData($form))

		let res = await api.post(action,params)
		if res
			if res.message
				imba.mount <app-snackbar data=res>
				authFormVisible = no

			# try to fetch data
			if res.code < 400
				await window.reloadUserData!
				if store.subscription
					return close!
				else
					window.zendesk.show!

			return res

	def load-pricing
		pricing = new Pricing
			pricing: store.me..pricing
			country: store.me..country
			coupons: store.me..coupons
			subscription: store.subscription
			usd: use-usd

	def payWithStripeCheckout
		let params =
			product_name: 'bootcamp3'
			purchase_url: window.location.href.indexOf('#') > -1 ? window.location.href.split('#')[0] : window.location.href
			coupon_code: bootcamp.coupon ? bootcamp.coupon.code : no
			use_usd: use-usd
			cid: cid

		let res = await api.post("/stripe/subscription/checkout", params)
		if res.message
			imba.mount <app-snackbar data=res>

		if res.code == 200
			return stripe.redirectToCheckout(sessionId: res.session)

	def toggleAuthForm
		authFormVisible = !authFormVisible

	def body
		<div[d:flex]>
			<header>
				<h3[d@bootcamp:none]> 
					"Get serious about your learning "
					
				
			<.section.copy>
				<bootcamp-usps>
			
			<.section[bg:gray1]>
				<div[d:none @bootcamp:vflex]>
					<h2> "Welcome back!"
					<p.small[mt:8]> "You are already a Scrimba Bootcamp subscriber. Go to { <a.small href="/mybilling"> "your billing page" }."
				
				if authFormVisible # Login form - show after user clicks continue to pay
					<form$form[w:min(90%,300px) mx:auto mt:4] action='/auth/signup' @submit.prevent.throttle.flag-busy=authenticate>
						<h4.bold[font-style:italic ta:center]> signin ? "Log in" : "Create your account" 
						<div[d:vgrid g:2]>
							<input type='email' name='email' placeholder='Email' autocomplete='email' required>
							<input type='password' name='password' autocomplete="new-password" placeholder='Password' required>
							<button.button.neutral[ w:100% ] type='submit'> signin ? "Log in" : "Create Account"
							<.group-head[my:1 c:gray6]> <p.small> "or"
							<a.button[w:100%] href="/auth/github?from={store.url.pathname}%23bootcamp"> "Sign {signin ? "in" : "up"} with GitHub"
							<p.small[mt:4 c:red8]>
								"Having issues signing in? "
								<a.small href="https://www.google.com/search?q=clear+cookies" target="_blank"> "Clearing your cookies will resolve it"
						if signin
							<p.small[mt:3 c:gray7]>
								"Don't have an account? "
								<a[ml:1].link.small @click=(signin = no)> "Sign up"
						else
							<p.small[mt:3 c:gray7]>
								"Already have an account? "
								<a[ml:1].link.small @click=(signin = yes)> "Log in"
						<div.busy-cover>
				else
					<div[d@bootcamp:none m:auto as:stretch w:100%]>
						<h4.bold[ta:left mb:0]> "Join The Scrimba Bootcamp"
						
						<.receipt.table[mt:2 mb:2]>
							# NOTE: Uncomment this for the monthly-vs-quarterly discount for bootcamp:
							<.row>	
								<.desc> "Scrimba Bootcamp subscription"
								<.sum> "{bootcamp.reference.plan_text}"

							if pro-reduction
								<.row[font-style:italic]>
									<.desc[d:vflex]>
										<span> "- current Pro subscription"
									<.sum> "- {Math.round(pro-reduction).toCurrency(pricing.currency, pricing.exchange-rate)}"
							
							if bootcamp.coupon
								<.row[font-style:italic]>
									<.desc[d:vflex]>
										<span> "Coupon: { <strong> bootcamp.coupon.title}"
									<.sum> "- {Math.round(Number(bootcamp.reference.plan - pro-reduction) * Number(1 - bootcamp.coupon.percent)).toCurrency(pricing.currency, pricing.exchange-rate)}"

							<.row.totals>
								<.desc> "Your price"
								<.sum> bootcamp.price.plan_text

						<.foot>
						# if store.user
						# 	<button[mx:0].button.md @click.prevent.throttle.flag-busy=payWithStripeCheckout>
						# 		<b[d:none @md:block]> "Continue with Payment"
						# 		<b[d@md:none]> "Continue"
						# else
						# 	<button[mx:0].button.md @click.prevent.throttle.flag-busy=(authFormVisible=!authFormVisible)>
						# 		<b[d:none @md:block]> "Continue with Payment"
						# 		<b[d@md:none]> "Continue"

						# <p.small[c:gray7]> "Payment problems? Follow "
						# 	<a.link.small target="blank" href="https://scrimba.com/links/payment-troubleshoot"> "this guide"


			<div[l:50% w:100% x:-50% b:0 pos:absolute h:10% px:2 d:none @sm:grid ac:center]>
				if store.user
					<p.small[ta:center]> "Buying for a group? Chat to us "
						<a.small href="mailto:help@scrimba.com?subject=Scrimba%20Group%20Deal%20%F0%9F%92%B0"> "for a deal."


	def render
		super()


