export const levels = {
	'1': 'Beginner'
	'2': 'Intermediate'
	'3': 'Advanced'
}

export const colors = [
	'lavendar'
	'bubble-gum'
	'baby'
	'crab-cake'
	'moss'
	'lemony'
]
export const weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
export const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
export const bootcamp-price = 199

export const level-descriptions = "Beginner = little or no experience in the topic.\nNoob = learn the basics of the topic\nIntermediate = reach a professional level.\nAdvanced = level up your already existing knowledge of the topic"

export def avatar-url user, s = 64
	if user
		"/avatars/uid/{user.id}/{s}"
	else
		"/avatars/scrimba/{s}"

export def uuid a,b
	b = a = ''
	while a++ < 36
		b += a*51 & 52 ? (a^15 ? 8^Math.random! * (a^20 ? 16 : 4) : 4).toString(16) : '-'
	return b

export const welcome-scrim = "/scrim/cof5644d6891c46f3f2e1b3de";
export const karma-notion-page = "https://scrimba-community-handbook.super.site/how-to-earn-karma-points"
export const rate-limit-message = "You've been rate limited. Please wait for a few minutes and before trying again."

export class UserTime
	prop d

	# Accepts a epoch timestamp to format a certain date to the user's local timezone and format.
	# Optional duration to display a from - to time
	constructor timestamp, duration = 0
		d = new Date(timestamp)
		dur = duration

	get user_time do #user_time ||= Intl.DateTimeFormat().resolvedOptions()
	get timezone do user_time.timeZone
	get locale do user_time.locale
	get weekday do new Intl.DateTimeFormat(locale, weekday: 'long').format(d)
	get month do new Intl.DateTimeFormat(locale, month: 'long').format(d)
	get month_short do new Intl.DateTimeFormat(locale, month: 'short').format(d)
	get time
		let fmt = new Intl.DateTimeFormat(locale, hour: 'numeric', minute: 'numeric')
		let endDate = new Date(d.getTime() + dur * 60000)
		let from = fmt.format(d)
		let to = fmt.format(endDate)
		if duration > 0 
			"{from} - {to}"
		else
			"{from}"
	def date options = {month: 'short', day: 'numeric'}
		new Intl.DateTimeFormat(locale, options).format(d)

	def timezone date
		let t = new Intl.DateTimeFormat(locale, timeZoneName: 'short').formatToParts(d)
		let tz = t.find do $1.type == 'timeZoneName'
		tz.value

	def formatDateDetails date, ev
		let details = ''
		for tz in infoZones
			let fmt = new Intl.DateTimeFormat(user_locale, {hour: 'numeric', minute: 'numeric', timeZone: tz})
			details += "{tz}: {fmt.format(date)}\n"
		details
