import './dialog'

tag env-modal < app-dialog
	css 1w:600px
	css 1t:80px
	css label fs:sm c:gray6
	css p fs:1.25rem
	css .title d:flex ai:center p:2
	css .item d:grid gtc:1fr 1fr 1fr bdb:1px solid $medium-grey
	css .secret fs:1.25rem max-width:200px text-overflow:ellipsis of:hidden ws:nowrap
	css .remove fs:1.25rem d:grid gtc:1fr 3fr g:2  ai:center jc:center h:100% fw@hover:bold
	
	action = '/me'
	new-key = ''
	new-value = ''
	env = []
	illegal-values = ['sk_live_', 'sk_test_', 'pk_live', 'pk_test_', 'whsec_']

	def mount
		return unless env.length < 1
		if store..me..user..env isa Array
			console.log 'env is array', store.me.user.env
			env = store.me.user.env
		else
			env = []
			console.log "env is not an array"
			imba.mount <app-snackbar code=400 data={ message: 'Env in unreadable format so we need to reset it.' }>
			

	def check-illegal-values
		return illegal-values.some do return new-value.startsWith $1

	def add
		if new-key and new-value
			if check-illegal-values!
				imba.mount <app-snackbar code=400 data={ message: 'Please do not use stripe keys on Scrimba.' }>
				return
			let exists = env.find do return $1.key == new-key.toUpperCase!
			if exists
				imba.mount <app-snackbar code=400 data={ message: 'You already have a variable with that name.' }>
				return
			env.push { key: new-key.toUpperCase!, value: new-value, created_at: new Date() }
			new-key = new-value = ''

			return unless const res = await api.post('/me', {vars: env })
			imba.mount <app-snackbar data=res> if res.message
			window.reloadUserData!
			$key.focus!

	def toggle e, show\boolean
		show = !show

	def remove item
		env.splice env.indexOf(item), 1
		return unless const res = await api.post('/me', { vars: env })
		imba.mount <app-snackbar data=res> if res.message
		window.reloadUserData!

	def handleinput e
		return e.target.value = e.target.value.replace(/[^a-zA-Z0-9_]/g, '')

	def body
		<>
			<h3> "User environment variables"
			<p[mt:2 mb:8]> 
				"Store secrets, API keys, and other environment variables. {<br>}"
				"{ <a[fs:1.25rem] href="https://scrimba.com/articles/environment-variables-an-enhanced-developer-experience/" target="_blank"> "Read more"} about Environment Variables."
			<p[mb:2]> "Create new variable"
			<.new[d:flex g:4]>
				<input$key[tt:uppercase] pattern="[A-Za-z0-9_]*" @keyup=handleinput type="text" bind=new-key placeholder="Key name">
				<input type="text" required bind=new-value maxLength="2048" placeholder="Value">
				<button.button.sm[ai:center mr:0 tt:none] @click.prevent=add> 'Add variable'
			<.list[mt:8]>
				for item in env
					<.item>
						<.title[fs:1.25rem]> item.key
						<.value[d:flex fld:row g:2 jc:flex-start ai:center]>
							<div[d:flex cursor:pointer] @click.prevent=item.show=!item.show> item.show ? <svg[h:25px w:25px] src="svg/eye.svg" > : <svg[h:25px w:25px] src="svg/eyeSlash.svg" >
							<.secret> item.show ? item.value : '••••••••••'
						<.delete[js:flex-end cursor:pointer]>
							<.remove @click.prevent=remove(item)>
								<svg[h:20px w:20px] src="svg/trashicon.svg" > 
								"Delete"
