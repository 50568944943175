import './dialog'

tag reset-password-modal < app-dialog
	action = '/reset-password'

	get token do
		if $web$
			let url = new URL(window.location.href)			
			url.searchParams.get('token')

	get aid do
		if $web$
			let url = new URL(window.location.href)
			url.searchParams.get('aid')

	def hydrate
		innerHTML = ''
		schedule!
		render!

	def body
		<>
			<div[d@guest:none].alert.danger> "You are already logged in - continue to log in with another account."
			<header[mb:3]> <h1[ta:center fs:lg]> "Set new password"
			<div[d:vgrid g:2]>
				<input type='hidden' name='aid' value=aid>
				<input type='hidden' name='token' value=token>
				<input[d:none] type='text' name='username' autocomplete='username' value=''>
				<input type='password' name='password' autocomplete="new-password" placeholder='Password (min 8 characters)' minLength="8" required>
				<button.button.neutral[w:100%] type='submit'> "Save"
	
	def completed res,event
		close!