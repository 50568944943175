import './dialog.imba'
import './course-tile'
import {colors} from '../helpers'

const topics = [
	{
		choice: "I have no idea where to start"
		options: [
			{ headline: "Dip your toes", gid: 'ghtmlandcss' }
			{ headline: "Go all in", gid: 'gfrontend' }
		]
	},{
		choice: "HTML & CSS"
		options: [
			{ headline: "Dip your toes", gid: 'ghtmlandcss' }
			{ headline: "Go all in", gid: 'gfrontend' }
		]
		# description: 'Info about the topic can be added as a description below the selected courses'
	},{
		choice: "Javascript"
		options: [
			{ headline: "Beginner", gid: 'glearnjavascript' }
			{ headline: "Go all in", gid: 'gjavascript' }
		]
	},{
		choice: "React"
		options: [
			{ headline: "Beginner", gid: 'glearnreact' }
			{ headline: "Go all in", gid: 'greact' }
		]
	},{
		choice: "Build with AI"
		options: [
			{ headline: "Try it out", gid: 'gaicoding' }
			{ headline: "Go all in", gid: 'gaiengineer' }
		]
	},{
		choice: "Responsive Design"
		options: [
			{ headline: "Tell me all about it!", gid: 'gresponsive' }
		]
	},{
		choice: "UI Design"
		options: [
			{ headline: "Try it out", gid: 'gdesign' }
			{ headline: "Go all in", gid: 'gdesignbootcamp' }
		]
	},{
		choice: "Python"
		options: [
			{ headline: "Try it out", gid: 'gpython' }
		]
	},{
		choice: "Something else"
	}
]

tag find-course-modal < app-dialog
	prop selected
	prop courses = {}
	prop ci = 0
	css 1w:min(90vw, 600px)
	css 1t:5vh
	css .topic w:100% ta:center my:4 py:2 bd:1px solid rd:10px cursor:pointer
		bc:$dark-grey @hover:$night shadow:none @hover:0px 2px 40px 0px rgba(0,0,0,0.3) tween:all 0.1s ease-out
	css .choices d:grid g:6 p:6 ji:stretch jac:center

	def get-course gid
		courses[gid] ||= store.get(gid)

	def hydrate
		innerHTML = ''
		schedule!
		render!

	get color
		if ci >= colors.length
			ci = 0
		"var(--medium-{colors[ci++]})"

	def select topic
		selected = topic

	def render
		ci = 0
		super

	def unmount
		selected = null
		unschedule!

	def body
		<>
			unless selected
				<header[mb:3]> <h4[ta:center us:none]> "Which of these subjects are you most interested in learning right now?"
				<div[d:vgrid g:2 gcg:1rem my:4]>
					<.topics> for topic in topics
						<.topic[bgc:{color}] @click=select(topic)>
							<p> topic.choice
			else
				<header[mb:3 d@lt-sm:none]> <h4[ta:left fw:600 us:none]> "Then these are good options:"
				<header[mb:8 d@sm:none]> <h4[ta:center fw:600 us:none]> "We recommend:"
				if selected.options
					<.courses[d@sm:hgrid mt:8 gtc@sm:repeat(2, 1fr) gcg@sm:1rem my:4]> for choice in selected.options
						<.course>
							<p[my:4 us:none ta@lt-sm:center].bold> choice.headline
							<course-tile[d:block] data=get-course(choice.gid)>
					<p[my:4]> selected.description
				else
					<a[my:4 d:block] href='/allcourses'> "Search for what you're looking for in our courses library"
				<[d:none @sm:grid gaf:column gcg:1rem jc:start]>
					<a[cursor:pointer].button.white.sm @click.prevent=(selected=null)> "Restart"
					<a[cursor:pointer].button.white.sm href='/allcourses'> "Go to course library"
				<[d:grid @sm:none grg:1.5rem mt:8]>
					<a[cursor:pointer].button.white.sm href='/allcourses'> "Go to course library"
					<a[cursor:pointer].button.white.sm @click.prevent=(selected=null)> "Restart"

	def completed res,event
		close!