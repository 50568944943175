tag navbar-announcement
	prop post\{context:string, title:string, link:string, live_from:string, expires_at:string, data:{ends_at:string, starts_at:string, linkText:string, countdown:boolean}, id:string}
	
	get announcement
		"{post.context}: {post.title}"

	get countdown
		# if started?
		# 	" live now!"
		# elif last-hour?
		# 	" live in {minutes-text}."
		# else
		# 	" live in {hours-text}{minutes-text}."
		if started?
			""
		elif last-hour?
			" {minutes-text}."
		else
			" {hours-text}{minutes-text}."

	get countdown? do post.data.countdown_to
	get started? do time-left < 0
	get finished? do Date.now! > (new Date post.data.ends_at).getTime!
	get last-hour? do time-left < 3600000 # 1000 * 60 * 60
	get time-left
		const now = Date.now!
		const countdown_to = (new Date(post.data.countdown_to).getTime!)
		countdown_to - now
	
	get minutes-left
		Math.round(time-left / (1000 * 60)) % 60
	get minutes-text
		return '' if minutes-left == 0
		minutes-left > 1 ? " {minutes-left} minutes" : " {minutes-left} minute"
	get hours-left
		Math.round(time-left / (1000 * 60 * 60))
	get hours-text
		hours-left > 1 ? "{hours-left} hours" : "{hours-left} hour"

	def close
		const key = "p:{post.id}:seen"
		window.localStorage.setItem key, "1"
		imba.unmount self

	css w:100% h:100% d:hflex ai:center jc:center
		@page-scrim d:none
		# hide it in course pages in mobile because there is a more important CTA
		@page-course d:none d@880:flex
		.container
			p:3 px:4 bgc:white rd:lg shadow:0 10px 15px 20px black/10 
			pos:fixed b:0 l:0 r:0 
			@lg b:auto r:auto l:auto pos:relative t:1 mx:8 shadow:lg
			@lg@sticky-header p:0 h:100% shadow:none px:8 t:0 bg:transparent o:0.99
			
			ease:quint-out zi:10
			@off o:0 scale:0.8
			# Layout
			d:grid g:3 gt:auto auto / repeat(16, 1fr) 
			@md gt:auto / repeat(16, 1fr) ai:center
		.close order:3 order@md:4  gc: -2 / -1
		.join ta:center order:4 gc:1 / -1
			@md order:3 gc:span 3 js:end
		.content gc:2 / -2 gc@md:span 11

	def render
		<self ease>
			<.container>
				<span[rd:lg bg:white c:$night d:flex ai:center as:center js:center]>
					<svg[size:6] src="svg/announcement.svg" aria-hidden="true">
				<span.content[c:$night fw:500]> 
					announcement
					countdown if countdown?
					
				if post.link
					<a.join[fw:600 bgc:white c:$night] href=post.link target="_blank">
						post.data..linkText or "Join now"
				<a.close.sm[o:0.7 o@hover:1 cursor:pointer bg:transparent d:flex ai:center as:center js:center] @click=close>
					<svg[size:3.75 c:$night] src="svg/close.svg">	
