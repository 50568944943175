import './cards'

tag site-menu-part
	context

	def resized e
		rect = getBoundingClientRect!
		active = $active
		emit('move',rect) if $active

	def mount
		place!

	def place
		if anchor = document.getElementById("{data.id}-menu-origin")..getBoundingClientRect!
			let card = children[0]
			rect = getBoundingClientRect!
			let vw = window.innerWidth
			let h = window.innerHeight
			let gl = 20
			let gr = vw < 700 ? 20 : 80 # depends on screen size?
			let ow = vw - gr # padding
			let ax = anchor.left + anchor.width/2
			let w = rect.width
			let l = ax - w/2
			let r = ax + w/2
			anchorLeft = ax + 'px'

			if r > ow
				l -= (r - ow)
			elif l < gl
				l = gl
			
			# center if the difference is pretty small

			style.left = cardLeft = l.round! + 'px'
			cardWidth = card.offsetWidth + 'px'
			cardHeight = card.offsetHeight + 'px'
			# rect = {width: card.offsetWidth}
			self

	set active bool
		$active = bool
		flags.toggle('active',!!bool)

	def rendered
		place! unless anchor

	def render
		<self>
			<{data.$type}-card data=data active=$active>

tag site-menu
	current = null
	css d:contents
		$height:140px
		$width:300px
		$left:20px
		$x:0
		$speed:0.3s

		1xp:24px
		1yp:24px
		zi:-1
		visibility:hidden
		tween:visibility 0.3s linear
		c:$night

		

		@!700
			$box w:calc(100vw - 40px)

	css $curtain
		pos:absolute h:calc(100vh - 1hdh) t:100% l:0 w:100%
		bg:linear-gradient(to bottom,hsl(211deg 17% 34% / 50%),hsl(211deg 17% 34% / 0%))
		tween:opacity $speed linear
		pe:none o:0 # hidden
		d:none
	
	css $hitbox pos:absolute h:30px l:0 t:1hdh w:100% h:20px pe:none # testing

	css $box
		min-height:80px
		h:$height w:$width
		bg:white rd:md pos:absolute t:100% l:0px
		mt:10px bxs:lg,xs
		tween:all $speed circ-in-out,opacity $speed linear
		l:$left w:auto r:calc(100vw - $left - $width)
		pe:none o:0 # hidden

	css $mask
		pos:absolute inset:0 of:hidden rd:inherit
	
	css $body
		pos:absolute t:0 l:0 d:block
		# transform: translate3d(calc($left * -1),0px,0px)
		tween:left $speed circ-in-out
		l:calc($left * -1)


	css $arrow
		$size:8px
		bdl:$size solid transparent
		bdr:$size solid transparent
		bdb:$size solid white
		pos:absolute
		b:100%
		l:-4px
		x: calc($arrow-left - $left)
		tween: all $speed circ-in-out

	css .card
		pos:absolute t:0 l:0 rd:inherit o:0 pe:none p:0 bg:white
		tween:opacity $speed linear
		&.active o:1

	css .show
		$hitbox pe:auto
		$curtain pe:auto o:0 d:block
		
		.card.active pe:auto
		visibility:visible
		tween:none

		$box pe:auto o:1		

	get items
		let items = []
		let base = store.home # store.root
		
		while base
			items.push(base)
			base = base.focus

		return items.filter(do !$1.released_at or $1.released_at < Date.now())

	get current
		store.menuFocus
	
	set current item
		store.menuFocus = item

	def reset cb
		style.setProperty('--speed','0s')
		offsetWidth
		cb()
		offsetWidth
		style.removeProperty('--speed')
		self

	def select item
		current = item
		show!

	def goNext
		let idx = items.indexOf(current)
		if let tgt = items[idx + 1]
			select(tgt)
	
	def goPrev
		let idx = items.indexOf(current)
		if let tgt = items[idx - 1]
			select(tgt)

	def hide
		if showing?
			flags.remove('show')
			current = null
			if returnFocusTo
				returnFocusTo.focus!
			$hiding = setTimeout(&,300) do yes
		self

	def show
		unless showing?
			returnFocusTo = document.activeElement
			clearTimeout($hiding)
			reset do
				render!
				for item in $body.children
					item.place!
				place!

			flags.add('show')
			setTimeout(&,10) do $box.focus!

	get showing?
		flags.contains('show')

	get render?
		current and api.payloads.common

	def pointerout e
		hide!
		yes

	def pointerin e
		yes

	def place
		let step = items.indexOf(current)
		let card = $body.children[step]
		# console.log 'place at card',card
		if card
			style.setProperty('--left',card.cardLeft)
			style.setProperty('--width',card.cardWidth)
			style.setProperty('--height',card.cardHeight)

			if card.anchorLeft != undefined
				style.setProperty('--arrow-left',card.anchorLeft)

	def rendered
		place!
		
	def render
		let items = items
		let step = items.indexOf(current)
		if !current and rendered?
			hide! if showing?
			return

		<self>
			<$curtain @click=hide @pointermove=pointerout>
			<$hitbox>
			<$box.no-mousetrap
				tabIndex=-1
				@keydown.left.stop=goPrev
				@keydown.right.stop=goNext
				@keydown.esc.stop=hide
				@pointerenter=pointerin
			>
				<$mask> <$body> for item,i in items
					<site-menu-part.card data=item context=self active=(current == item)>
				<$arrow>