export def share data, _url
	let msg
	let url = _url
	# await persistIfLocal!

	return unless data
	
	# If the scrim has a sharing format we iterate over array references `[arrayName]` 
	# and replace it with a random picked element from the corresponding array. 
	if data..format
		def pick-random(arr\Array) do arr[Math.floor(Math.random! * arr.length)]
		const t = msg = data.format
		const reg = /\[([\w]*)\]/g
		let match = reg.exec(t)
		while match != null
			let n = pick-random(data[match[1]])
			msg = msg.replace(match[0], n)
			match = reg.exec(t)

		msg = msg.replace '{url}', url
		# # We also replace variable references `{varName}` with a self scoped variable.
		# const vars = /\{([\w]*)\}/g
		# console.log 'vars to replace', vars
		# let m = vars.exec(t)
		# while m != null
		# 	msg = msg.replace(m[0], self[m[1]])
		# 	m = vars.exec(t)
		
	else
		msg = "Check out my code scrim {data.branch.title!} - {url} on @scrimba"


	# To reply to tweets from a scrim:
	# if meta and meta['twitter-reply'] # seems $parent is not the parent locally. Is it in prod since it works there?
	# 	twitter-url += "&url={meta['twitter-reply']}"
	const twitter-url = "https://twitter.com/intent/tweet?text={window.encodeURIComponent(msg)}&related=@scrimba"
	window.open twitter-url, "_blank"
