import './dialog.imba'
import './course-tile'

tag completed-course-dialog < app-dialog
	css $box bgc:$medium-moss
	css 1w:min(90vw, 600px)
	css 1t:5vh
	css .foot
		d:vflex ai:flex-end mt:6
		button mb:15px

	prop course\Course
	#next-courses\Course[] = []
	#fdcp-course = store.get('gfrontend')

	def mount
		setTimeout(&,0) do
			flags.add('show')
			api.track('Course', 'Completed dialog', course.id)
			window.gtag "event", "course_completed", { course_id: course.id }

		const course-ids = store.course.metadata["next-courses"] or ['greact', 'gdesign', 'gfrontend']
		#next-courses = course-ids.map do 
			store.get $1
		imba.commit!

	def body
		# TODO: Button to share celebration on social media or Discord community?
		<[of:hidden]>
			<header> <h3[ta:center]> "Yay! You've completed { <br> }{ course.title } 🎉"

			# Free members - show FDCP course
			<[d@subscriber:none]>
				<header[my:10]> <h4[ta:center fw:600 us:none]> "Now go pro, and continue learning with the Frontend Developer Career Path"

				<.courses[my:4 m:auto as:stretch w:50%]> <.course>
					<course-tile[d:block max-height:300px] data=#fdcp-course>

				<.foot>
					<p.small[mt:8 fs:1rem c:gray7]> "or check out { <a.small href="/allcourses"> "other courses" }."

			# Subscribers - show recommended courses
			<[d:none @subscriber:block]>
				<header[my:10]> <h4[ta:center fw:600 us:none]> "We recommend you to take one of these courses next:"

				<.courses[d@sm:hgrid mt:8 gtc@sm:repeat(2, 1fr) gcg@sm:1rem my:4]> for c, i in #next-courses when i < 3 and c.title
					<.course> <course-tile[d:block] data=c>

				<.foot>
					<p.small[c:gray7]> "See { <a.small href="/allcourses"> "all courses here" }."
