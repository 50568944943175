import './dialog'

tag forgot-password-modal < app-dialog
	action = '/forgot-password'

	def body
		<>
			<div[d@guest:none].alert.danger> "You are already logged in - continue to log in with another account."
			<header[mb:3]> <h1[ta:center fs:lg]> "Reset Password"		
			<div[d:vgrid g:2]>
				<input type='email' name='email' autocomplete="username" placeholder='Your email-address' required=true>
				<button.button.neutral[w:100%] type='submit'> "Reset password"
			<.group-head[my:3 c:gray6]> <p> "or reauthenticate"
			<a.button[bg:blue2 @hover:blue3 c:blue8 $bc:blue6 w:100%] href="/auth/github?from={store.url.pathname}"> "Log in with GitHub"
			<footer$footer[c:gray6 ta:center]>
				<span> "We will send you a link to reset your password. If you signed up via github you need just need to log in again."

	def completed res,event
		close!