import './dialog'

tag user-delete-modal < app-dialog
	css 1w:600px
	css 1t:80px
	css label fs:sm c:gray6
	action = '/me/delete'
	
	get subscription
		store.subscription or no
	get processor
		subscription.processor or no
	get activeSubscription?
		subscription and subscription.status == 'active'

	def completed res,event
		if res.code == 200
			console.log "Success, redirecting"
			window.location.hash = ''
			window.location.pathname = '/auth/logout'
		else
			console.log "Error deleting account", res.code, res.data
	
	def body
		<>
			if activeSubscription? and processor != 'scrimba'
				<div[mb:4]>
					<h2> "Stop subscription before deleting account"
					<div[mb:4]> "You need to stop your subscription before we can delete your account."
					<a.button.button.neutral.sm[mr:0] href="/mybilling"> "Go to billing page"
			else
				<div[mb:4]>
					css mb:4
						p mb:2
					<h2> "Are you sure?"
					<p> "You will lose all certifications, course progress and access to all paid courses. This action is permanent and can not be reversed."
					<p> "We will remove all your public information, remove the information we have on your account and remove you from external systems like e-mail notifications and analytics."
					<button.button.red.sm[mr:0] type="submit"> "Yes, delete my account"
