import './dialog.imba'
import './course-tile'

tag sign-up-success-modal < app-dialog
	css 1w:min(90vw, 600px)
	css 1t:5vh
	css section my:4
	

	def hydrate
		innerHTML = ''
		schedule!
		render!

	def mount
		setTimeout(&,1000) do tolt-signup!
		self
	
	def tolt-signup
		if window.tolt_referral
			let res = await window.tolt.signup(store.me.user.email)

	get gmailuser?
		return !!store..me..user..email and store..me..user..email.indexOf('@gmail.com') > -1


	def body
		let name = ''
		name = " {name}!" if store..me..user..first_name

		<>
			<header[mb:3 bgi:url('/static/round-avatar-dark.png') bgp:top right bgs:50px bgr:no-repeat]> 
				<.intro[w:65% mb:8]>
					<h3[ta:left us:none]> "Welcome on board{name}! 🎉"
					<p> "We are super excited to bring you on board with free learning resources, and the Scrimba Discord community."
			<main>
				<p.bold> "With your Scrimba account you have access to:"
				<section>
					<p.bold> "Free courses"
					<p> "Find the right course for you from {<a href="/allcourses"> "all free courses"}."
				<section>
					<p.bold> "Scrimba Discord community"
					<p> "Say hello, share your progress, and ask questions! {<a href="/discord" target="_blank"> "Join the Scrimba Discord community"}."
				<section>
					<p.bold> "Expect an email soon!"
					if gmailuser?
						<p>  """Check your inbox ({<a href="https://mail.google.com/mail/u/0/#search/from%3A%40scrimba.com+in%3Aanywhere" target='_blank'> "direct link to gmail"}) or sign up 
							{<a href="https://scrimba.ck.page/signup"> "here"}
							to make sure you get important emails about Scrimba courses and product updates."""
					else
						<p>  """Check your inbox or sign up 
							{<a href="https://scrimba.ck.page/signup"> "here"}
							to make sure you get important emails about Scrimba courses and product updates."""

				<[d:grid gaf:column gcg:1rem jc:start]>
					<a[cursor:pointer].button.white.sm @click=close> "Close"
