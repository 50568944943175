const PrivacyOptions =
	owner: ["Private","Only you"]
	guests: ["Public","Anyone with the link"]
	users: ["Members","Only logged in users"]
	members: ["Subscribers","Only Pro members"]

tag scrim-edit-dialog < app-dialog
	payload = {}

	css
		1w:600px
		w:1w
		l:calc(50% - 0.5w)
		t:200px
		zi:2010
		pos:fixed
		$box min-width:550px

	css label d:vflex ja:stretch
		> div c:gray6
		> input@checked + div
			c:gray9

	css .privacy
		d:hgrid of:hidden rd:md bd:3px solid gray3 bg:gray3

	css .option
		d:vflex ja:center py:2 rd:md tween:styles 0.2s quart-out
		span c:gray6
		c:gray6 @hover:gray7 .checked:gray8
		bg:clear @hover:white/20 .checked:white

	def mount
		action = "/scrims/{data.id}"
		payload = {
			title: data.title
			access: data.access
			description: data.description or ''
		}
		setTimeout(&,0) do flags.add('show')
	
	get formData
		payload

	def completed res
		data.$patch(res.data) if res.data
		document.title = data.title
		close!

	def publishScrim e
		console.log 'publishing!'
		if data.branch
			let submitted = await submit(e)
			await data.branch.publish!

	# Avoid having null as access level
	def validate e
		let value = e.target.value or e.target.parentElement.value
		if value == payload.access
			return e.preventDefault!
		payload.access = value

	def body
		<div[d:contents]>
			<input[mb:3] placeholder='Title' bind=payload.title>
			<textarea placeholder='Description' bind=payload.description>
			<.group-head[my:3 c:gray6]> <span> "Privacy"
			<div[d:vgrid g:2]>
				<div.privacy> for own value,desc of PrivacyOptions
					<button[cursor:pointer].option bind=payload.access value=value type='button' @click.validate>
						<b[mr:1 tt:capitalize fw:600 ua:none]> desc[0]
						<p.small[fs:xs ua:none]> desc[1]

			<div[d:hflex j:flex-end mt:6]>
				if data.publishable? and data.branch
					<button.button.neutral.sm[mx:2] type='submit'> "Save"
					<button.button.sm[mx:0] type='button' @click.prevent.flag-busy('.modal')=publishScrim> "Save and Publish"
				else
					<button.button.sm[mx:0] type='submit'> "Save Changes"
				# if data.publishable?
				#	<button.button.sm[mx:0] type='button' @click.prevent=publishScrim> "Publish"