import './dialog'

tag sign-in-modal < app-dialog
	action = "/auth/login?from={redirect-path}"
	get redirect-path do store.url.pathname == '/' ? '/dashboard' : store.url.pathname

	css $box max-width:23rem

	def body
		if window..flags..contains('mod-embedded')
			if window.flags.contains('mod-guest')
				return <.group-head[my:3 c:gray6]> <p> "To log in, go to { <a.link[fs:inherit] href="https://scrimba.com" target="_blank"> "scrimba.com" }"
			else
				return <.group-head[my:3 c:gray6]> <p> "You are already logged in"

		<>
			<p[d@guest:none].alert.danger> "You are already logged in - continue to log in with another account."
			# <header[mb:3]> <h1[ta:center fs:lg]> "Log in"
			<a.button[bg:blue2 @hover:blue3 c:blue8 $bc:blue6 w:100%] href="/auth/github?from={redirect-path}"> "Log in with GitHub"
			<.group-head[my:3 c:gray6]> <p.small> "or with email"
			<[d:vgrid g:2]>
				<input type='email' name='email' autocomplete="username" placeholder='Email' required>
				<input type='password' name='password' autocomplete="current-password" placeholder='Password' required>
				<button.button[w:100%] type='submit'> "Log in"
			<p.small[mt:4 c:red8]>
				"Having issues logging in? "
				<a.small.link href="https://www.google.com/search?q=clear+cookies" target="_blank"> "Clearing your cookies will resolve it"
			<footer$footer>
				<span> "New here? {<a.small.link href="#sign-up"> "Sign Up"}"
				<a.small.link[ml:auto] href="#forgot-password"> "Forgot password?"

	def completed res,event
		const page_url = window.location.href.indexOf('#') > -1 ? window.location.href.split('#')[0] : window.location.href
		api.track('Users','Login', page_url)
		if res.code == 200 and res.data..redirect_to
			let url = new URL(res.data.redirect_to, window.location.origin)
			window.location = url.href
			
		else
			reload!