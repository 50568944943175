import './dialog.imba'
import './course-tile'

tag github-education-modal < app-dialog
	css $box bgc:$white
	css 1w:min(90vw, 600px)
	css 1t:5vh

	def body
		<[of:hidden]>
			<header>
				<h3[ta:center]> "Welcome Github Education Student!"

			<p[mb:4]> "The Scrimba Student Pack is now available for you in just 3 steps: "
			<p> "1. Click the button below"
			<p> "2. Redeem your gift subscription"
			<p> "3. Get started on any Scrimba course you like."

			<a.button[my:8] href="#join"> "Start learning"
