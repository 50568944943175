import './playlist-edit-dialog'
import './scrim-edit-dialog'
# import './shapes'
tag any-row
	css d:contents $progress:0 $tint:indigo1-5

	css $thumb
		bgs:cover bgp:center center pos:relative a:stretch w:1.1rh h:100% bd:4px solid white rd:inherit
		# .title pos:absolute inset:2 d:flex ja:center fs:26px/1.2 ws:initial fw:700 ta:right

	css $main
		d:vflex a:flex-start j:center p:2 px:4 fl:1 ws:nowrap of:hidden
	
	css .title fw:600 fs:md/1.2 text-overflow:ellipsis of:hidden td:none c:inherit max-width:100%
		@before c:gray7
	
	css .menu fw:600 fs:md/1.2 text-overflow:ellipsis of:hidden
	css .trigger c:$night @hover:gray7 td:none
	css .tools cursor:pointer

	css &.prefixed .title @before content:$prefix

	css .meta fs:sm/1.2 c:gray6 fw:400 d:hflex j:flex-start a:center as:stretch
		span + span @before content:"|" o:0.4 mx:1ex
		.link c:$linky td:none @hover:underline

	css .tools o:0 pe:none pl:1 ml:auto
	css self @hover .tools o:1 pe:auto

	css .tool
		all:unset d:flex ja:center
		w:6 h:6 rd:sm
		o:0.9 @hover:1
		c:gray6 @hover:blue5 # .checked:blue6
		svg w:4 h:4 va:top
		&.red c:red5 @hover:red6
		&.green c:green5 @hover:green6

	css .bar
		pe:none d:block pos:absolute
		b:0 l:0 r:0 h:4px rdb:md bg:gray2/60
		# inset:0

		@before 
			content: " " pos:absolute pe:none inset:0
			tween: width quart-out 0.2s
			bg:black/8 w:calc($progress * 100%)
	
	css .progbar
		h:6px rd:md bg:gray4/40 d:block pos:relative w:14 ml:auto ml:1
		@before
			content:" " pos:absolute rd:inherit
			bg:green5 w:calc(max($progress * 100%,6px)) h:100% l:0 o:calc(max(0.5,$progress))


	css $item td:none d:hflex a:center c:inherit bd:1px solid gray4 rd:md mb:1 h:1rh pos:relative
	
	css &.numbered
		# $main bg:red5
		.title @before
			c:blue8
			counter-increment: item
			content: counter(item) ". "

	css &.compact
		# .bar h:100% rdb:inherit bg:clear w:auto r:0 l:0
		1rh:32px
		.tool w:4 h:4 ml:2
		$main d:hflex j:flex-start a:baseline
			.title mr:1 fw:500
			.meta d:contents
			.meta fs:xs/1.2
	
	css &.inline
		.bar h:1px rdb:0 bg:gray3 w:auto r:0 l:0 b:0 d:none
			@before bg:blue6/20
			@after d:block pos:absolute l:calc($progress * 100%) w:3px h:3px mt:-1px bg:blue6 content: " " rd:1px

		$main p:1
		$item bd:none rd:md mb:0 py:0 px:1
		$item @hover bg:gray5/10
		&.selected $item bg:blue1-3

	css &.header
		$tools pe:auto o:1
		$item bd:none cursor:default mb:0 px:6 bg:gray2
		$main px:0
		.tool w:6 h:6 bg:clear @hover:gray5/10
		.title fs:lg/1.2 prefix:"" c:$night
		.meta fs:xs/1.2
			.progbar ml:2 mt:1px

		.bar h:1px rdb:0 bg:black/3 w:auto r:0 l:0 b:0 d:none
			@before bg:blue6/20
			@after d:block pos:absolute l:calc($progress * 100%) w:3px h:3px mt:-1px bg:blue6 content: " " rd:1px
	
	def mount
		# use scrollContainer.scrollBy instead of self.scrollIntoView because scrollIntoView also scrolls the note card out of view
		const scrollContainer = self.closest('.js-scroll-container')
		const el\HTMLElement = self.firstChild
		if scrollContainer != null and classList.contains('selected') and el != null
			const containerHeight = scrollContainer.clientHeight
			const rowBottom = el.offsetTop + el.offsetHeight
			const distanceOutside = rowBottom - containerHeight # how far outside the visible area is the row
			const pad = 30 # little extra to push it nicely up into the visible area
			if distanceOutside > 0 # if its out of view, scroll it in
				scrollContainer.scrollBy({top: distanceOutside + pad})


	<self> <a.small href=data.url>
		<.title> data.title

tag scrim-row < any-row
	css $tint:blue1-5
	css &.note $prefix: "" $tint:yellow1-5
		.progbar d:none

	css &.header 
		.progbar d:none

	css &.tutorial $prefix: "Lesson "
	css &.template $prefix: "Template "
		.progbar d:none
	# css &.inline.compact .meta d:none
	css $tools d:flex pos:absolute r:0 h:100% ai:center bgc:transparent mr:2 w:$extra-width jc:end

	css self.is-mine @hover .time o:0
	css self.is-mine @hover .progbar o:0
	css .extras as:center js:end ta:right h:100% w:100% d:flex jc:end ai:center
		.time d:block fs:sm

	css $extra-width: 100px

	def discard
		let ok = window.confirm("Are you sure you want to delete this scrim?")
		if ok
			if branch
				await branch.discard!
			else
				let res = await api.del("/resource/{data.id}")
				if res..message 
					imba.mount do <app-snackbar data=res>
					parentNode.removeChild(self) # Feels like a hack

	def save
		await branch.persist!

	def publish
		let ok = window.confirm("Are you sure you want to publish this scrim?")
		if ok
			if branch
				# will not happen in the dashboard, but maybe when we store it locally?
				await branch.save!
			else
				let res = await api.post("/scrims/{data.id}/publish",{})
				if res..message
					imba.mount do <app-snackbar data=res>

	def edit
		imba.mount <scrim-edit-dialog data=data>

	get branch
		data.branch

	get creator
		data.user or data.creator

	get released?
		return yes unless (data.released_at and new Date(data.released_at).getTime! > Date.now!)
		no

	def render
		return unless released?
		<self.{data.type} --progress=data.progress .is-mine=(data.mine?)> <a$item data-id=data.id href=data.contextualUrl(context or data.view-context)>
			# <$bar>
			# <$body>
			<$main[ws:initial]>
				<span.title.truncate[max-width:85% fl:1]> data.title or 'Untitled Scrim'
				<span.meta>
					if data.duration
						<span.duration> (data.duration or 0).format!
					
					# "{data.scrims.length} lessons - {data.duration.format 'time'}"
					<.progbar>
			<$tools>
				if data.mine?
					if store..me..user..houdini
						<.tool[d:none @page-dashboard:flex mr:1] @click.stop.prevent=publish> <svg src="svg/book-open.svg" [mt:2px]>
					<.tool @click.stop.prevent=edit> <svg src="svg/edit.svg" >
					<.tool.red @click.stop.prevent=discard> <svg src="svg/trash-2.svg" >
					if data.local?
						<.tool.green @click.stop.prevent=save> <svg src="svg/check.svg" >
				
tag note-row < scrim-row
	css self @hover .time o:0
	css self @hover .progbar o:0
	get parent
		data.parent_id ? store.get(data.parent_id) : no

	get parent-title
		parent.title or ''
	
	get tooltip-parent-title
		parent.title or ''

	get course-title
		if data.gid
			let course = store.get(data.gid)
			return "{data.gid == 'gfrontend' ? 'The Frontend Path' : course.title}: " 
		''

	get viewContext
		if data.type == 'note' and data.gid
			return store.get(data.gid)
		context or data.view-context

	get actual-contextual-link
		if data.type == 'note' and data.parent_id
			return parent.contextualUrl(viewContext)
		data.contextualUrl(viewContext)

	<self.{data.type} --progress=data.progress .is-mine=(data.mine?)> <a$item data-id=data.id href=actual-contextual-link>
		# <$bar>
		# <$body>
		<$main[ws:initial d:grid gtc:minmax(0,1fr) $extra-width grid-template-rows:1fr 1fr ai:start ji:start]>
			<span.title.truncate title=data.title> data.title or 'Untitled Scrim'
			<span.extras>
				<.time>
					if data.mine? and data.created_at
						<span.ago> data.created_at.toTime 'old'
					elif data.duration
						<span.duration> (data.duration or 0).format!
				<$tools>
					if data.mine?
						if store..me..user..houdini
							<.tool[d:none @page-dashboard:flex mr:1] @click.stop.prevent=publish> <svg src="svg/book-open.svg" [mt:2px]>
						<.tool @click.stop.prevent=edit> <svg src="svg/edit.svg" >
						<.tool.red @click.stop.prevent=discard> <svg src="svg/trash-2.svg" >
						if data.local?


			<span.meta[max-width:100%]>
				if data.note?
					<span.context.truncate title=tooltip-parent-title> "{<strong> course-title} {parent-title}"
							
				# "{data.scrims.length} lessons - {data.duration.format 'time'}"
				<.progbar>
										

tag course-row < any-row
	css $prefix: "Course "

	css .selected & .resume d:none 

	def render
		let next = data.next-up
		<self --progress=data.progress> <a$item href=data.url>
			<$bar>
			# <art-pattern$thumb data=data.art>
			<$main>
				<span.title[fl:1]> data.title or '---'
				<span.meta>
					if next and next != store.scrim
						<span.resume> <a.link.small href=next.contextualUrl(data)> "Resume"

					<span.lessons> "{data.casts_count} lessons"
					<span.duration> (data.casts_duration * 1000).format('time')
					<span.students> "{data.student_count} students"
					<.progbar>

tag module-row < any-row
	css $prefix: "Module "
	css &.numbered
		.title prefix: "Module " counter(item) " "

	def render
		let next = data.next-up
		<self --progress=data.progress> <a$item href=data.url>
			# <$bar>
			# <art-pattern$thumb data=data.art>
			<$main>
				<span.title[fl:1]> data.title or '---'
				<span.meta>
					if next and next != store.scrim
						<span.resume> <a.link.small href=next.contextualUrl(data)> "Resume"
					<span.lessons> "{data.scrims.length} lessons"
					<span.duration> (data.duration).format('time')
					<.progbar>
				# 	<span.lessons> "{data.casts_count} lessons"
				#	<span.duration> (data.casts_duration * 1000).format('time')
				#	<span.students> "{data.student_count} students"

tag playlist-row < any-row
	css $prefix: "Playlist " $tint:coral2
	css &.numbered .title prefix:""
	prop mine = no

	def edit-cb res
		if res.data
			setTimeout(&,500) do window.location.reload!

	def edit
		imba.mount do <playlist-edit-dialog[t:5vmin] data=data cb=edit-cb>

	def discard
		let ok = window.confirm("Are you sure you want to delete this playlist?")
		if ok
			let res = await api.del(data.url)
			if res..message 
				imba.mount do <app-snackbar data=res>
				parentNode.removeChild(self) # Feels like a hack

	get course-title
		if data.gid
			let course = store.get(data.gid)
			return 'Unpublished course' unless course # Can happen for unpublished courses
			return "{data.gid == 'gfrontend' ? 'The Frontend Path' : course.title} " 
		no
		
	def render
		let next = data.next-up
		<self --progress=data.progress> <$item.trigger>
			<$main>
				<div[d:flex w:100% jc:space-between]>
					<a.small[td:none c:inherit] href=data.url> <span.title[of:inherit]> data.title or '---'
					if mine
						<$tools[d:hflex]>
							<.tool @click.stop.prevent=edit> <svg src="svg/edit.svg" >
							<.tool.red @click.stop.prevent=discard> <svg src="svg/trash-2.svg" >
				<span.meta>
					if course-title
						<span.course> "{course-title}"
					<span.count> "{data.scrims.length} lessons"
					<span.duration> data.duration.format('time')
					<.progbar>

# tag note-row < scrim-row

# tag template-row < scrim-row
