import './dialog'

const BGs = [
	'https://assets.scrimba.com/cdn-cgi/imagedelivery/R2lO609f_fcseOhouaQLEg/blobs/sha1:3f49ad5b2c85f4a185d4b04e9eee912fefc7f798/01_image-1700136890814/public'
	'https://assets.scrimba.com/cdn-cgi/imagedelivery/R2lO609f_fcseOhouaQLEg/blobs/sha1:33ebcb6cb0011f642c832db99c9552c914a26dc9/01_image-1700136891438/public'
	'https://assets.scrimba.com/cdn-cgi/imagedelivery/R2lO609f_fcseOhouaQLEg/blobs/sha1:824f263f6eb3f46875a3f22013b08eb7dd85ca56/01_image-1700136890497/public'
	'https://assets.scrimba.com/cdn-cgi/imagedelivery/R2lO609f_fcseOhouaQLEg/blobs/sha1:4320ed54de5da361b91770da6fd1a80334b52a3c/01_image-1700136891127/public'
]

class Slide
	prop id
	prop svg
	prop blobId

	selected? = no
	selectedImage
	initial-svg

	def constructor(props)
		super(props)
		fetch-slide!
	
	def fetch-slide
		const res = await space.api!.fetchBlob(blobId)
		svg = await res.text!

		imba.commit!

	get space
		global.window.SP

	get actualImages
		const i = []
		for img in imageElements
			const src = img.getAttribute('xlink:href')
			i.push src unless i.includes(src)
		i
	get imageElements
		document.getElementById("slide-{id}")..getElementsByTagName('image') or []

	def select
		selected? = !selected?
		if !selected?
			selectedImage = undefined
		elif actualImages.length and !selectedImage
			selectedImage = actualImages[0]

	def selectImage
		selectedImage = $1

	def getHrefAttr(link)
		`xlink:href="{link}"`
	
	def replaceImages(newSrc)
		initial-svg ||= svg
		svg = svg.replaceAll(getHrefAttr(selectedImage), getHrefAttr(newSrc))
		selectedImage = newSrc
	
	def reset
		svg = initial-svg

	get treated?
		BGs.some do(bg) svg..includes bg

tag SlideTag
	prop slide

	def onClick(e)
		if e.shiftKey
			emit('shift-select', slide)
		else
			slide.select!

	def render
		<self[cursor:pointer pos:relative]>
			if slide.treated?
				<[pos:absolute t:0 r:0 zi:1001]> "✅"
			<div @click.prevent=onClick>
				<div[pe:none] [bd:4px amber4 solid rd:2px]=slide.selected? id="slide-{slide.id}" innerHTML=slide.svg>
			<div[w:100% h:12px d:hflex ai:center g:2px]> for img in slide.actualImages
				
				<div[bg:black/20 d:hflex ai:center size:80px us:none]> 
					<img[bd:1px black solid rd:2px max-width:100%]
						key=img
						@click=slide.selectImage(img)
						src=img 
						[bd:4px pink4 solid]=(slide.selectedImage === img)
					>


tag edit-slides-modal < app-dialog
	slides = []
	processed = []
	processing-count

	css 
		$box w:95% max-width:90%

	get space
		global.window.SP

	get selected
		slides.filter do $1.selected?

	def mount
		selected-bg = BGs[0]
		setTimeout(&, 500) do
			slides = space.slides!.items!.SLIDES.map(do new Slide $1.data!)
			imba.commit!

	def toggle e, show\boolean
		show = !show

	def save
		# save slides in the db
		
		processing-count = selected.length
		processed = []
		for slide in selected
			# debugger
			preview(slide)
			await window.fetch "/blobs/{slide.blobId}?backup=1", {
				credentials: 'include'
				method: 'POST'
				body: Buffer.from(slide.svg)
				headers: {'Content-Type': 'application/octet-stream'}
			}

			processed.push slide
			for sl in space.widgets!.array! when sl.data!..id == slide.id
				console.log "::sk",sl
				sl.fetchBody!

	def reset-in-db
		let ids = selected.map(do $1.blobId)
		if selected.length == 0
			return unless window.confirm("Do you want to reset all slides?")
			ids = slides.map(do $1.blobId)

		const res = await window.fetch "/blobs/reset", {
			credentials: 'include'
			method: 'POST'
			body: JSON.stringify({ids})
			headers: {'Content-Type': 'application/json'}
		}
		slide.fetch-slide! for slide in slides

	def preview(slide)
		slide.replaceImages(selected-bg)

	def reset-preview
		slide.reset! for slide in selected

	def select-all
		slide.select! for slide in slides when !slide.selected?

	def deselect-all
		slide.select! for slide in slides when slide.selected?

	def shift-select(e)
		const slide = e.detail
		const last-selected-slide = selected[selected.length - 1]
		const si = slides.indexOf(slide)
		const lsi = slides.indexOf(last-selected-slide)
		const range = [si, lsi].sort(do(a, b) a - b)

		if last-selected-slide !== slide
			slide.select! for slide, i in slides when !slide.selected? and i >= range[0] and i <= range[1]
		else
			slide.select!

	get errored
		processed.filter(do $1.errored?)

	get updated	
		processed.filter(do !$1.errored?)

	def body
		<div>
			css button m:0
			<div[d:hflex ai:center jc:flex-start w:100%]>
				<span[fl:1 d:hflex ai:center g:4]>
					<h3> "Edit Slides"
					if processing-count
						<span[fs:md fl:1]>
							if errored.length == 0 and updated.length === processing-count
								<span[c:green7]> "Successfully updated"
							else
								<span>
									<span[c:red4 mr:2]> "{errored.length} errors" if errored.length
									<span[c:green7]> "{updated.length} success"
									<span> " / {processing-count} total"

				<div[d:hflex ai:center as:flex-end]>
					<button.button.submit type="button" @click=save> "Save"
					<button.button.white[hue:pink] type="button" @click=reset-in-db> "Reset to original"
			<div[d:hflex ai:center jc:left g:8px]>
				<h4> "Select background"
				css .selected bd:4px amber4 solid rd:2px
				<div[d:hflex ai:center mb:12px g:4]> for bg in BGs
					<img[w:100px cursor:pointer] key=bg src=bg .selected=(selected-bg == bg) @click=(selected-bg = bg)>
			<div[d:hflex ai:center g:2 mb:4]>
				<button.button.sm.white @click=select-all type="button"> "select all"
				<button.button.sm.white @click=deselect-all type="button"> "deselect all"
				<span[fs:md c:gray5 ml:2]> "ℹ hold SHIFT to multi select"
			<div[d:grid g:8px rg:40px gtc:repeat(auto-fill, 240px)]> for slide, i in slides
				<SlideTag slide=slide @shift-select=shift-select>
