import './shapes'

tag course-tile
	css &
		@before content:" " d:block pt:1tileheight

	css .art
		bgs:cover bgp:center center pos:relative
		.title pos:absolute inset:2 d:flex ja:center fs:26px/1.2 ws:initial fw:700 ta:right

	css .card
		bg:white rd:xl p:0 fs@sm:xs fw:500 d:vflex min-height:240px pos:absolute inset:0
		c:$night td:none zi:1
		rotate:0deg scale:1 backface-visibility:hidden z:0
		tween:transform 0.2s quart-out
		bxs:md 

		bxs:none
		bxs:0px 2px 0px 0px black/6
		@before content:" " d:block pos:absolute inset:0 pe:none rd:inherit zi:1
			bd:1px solid black/10

	css .play w:32px h:32px rd:16px jas:center min-width:32px # bg:purple3 @hover:purple4

	css svg stroke:currentColor
		polygon stroke-linejoin:round stroke-width:2px stroke:currentColor fill:currentColor

	css &.free .status suffix: "Free"
	css &.enrolled .status suffix: "Enrolled"

	css .main bg:white tween:transform 0.2s quart-out
		@before
			content:" " d:block
			pos:absolute l:0% t:-10px w:100% h:10px bg:white rdt:100%

	css self @hover .card
		# rotate:1deg scale:1.02
		# y:-6px
		.main y:-4px
		art-pattern $hs:1.2 $rot:4deg $scale:1.3
		

	css self @odd @hover .card
		art-pattern $hs:1.2 $rot:-4deg $scale:1.3
		# rotate:-1deg scale:1.02
		# y:-6px

	css .ribbon
		size:65px
		of:hidden
		pos:absolute
		@before, @after pos:absolute zi:-1 content:'' d:block bd:5px solid #2980b9
		span
			pos:absolute
			d:block
			w:112px
			p:3px 0
			bgc:$linky
			shadow:0 2px 3px rgba(0,0,0,.1)
			c:#fff
			fw:700
			fs:1.2em
			ts:0 1px 1px rgba(0,0,0,.2)
			tt:uppercase
			ta:center
			zi:1

	css .ribbon-top-left t:-2px l:-2px
		@before, @after bct:transparent bcl:transparent
		@before t:0 r:11px
		@after b:11px l:0
		span r:-10px t:6px rotate:-45deg

	get image
		"url(/static/covers/{data.id}.svg)"

	get teacher
		data.creator or {}

	get avatar
		teacher.avatar-url ? teacher.avatar-url! : undefined

	get level
		switch data.level
			when 1
				"Beginner"
			when 2
				"Intermediate"
			when 3
				"Advanced"

	get tags
		data.flags ||= (data.tags or []).join(' ').replace(/\#/g,'')
	
	get duration
		4

	def hydrate
		super
		innerHTML = ''
	
	<self .{data.type} .{tags} [max-width:350px]> <a.card href="/learn/{data.id.slice(1)}" title=data.title>
		<art-pattern[w:100% pt:0.58tileheight rdt:xl] heading=data.title data=data.art>
		<span[d:hflex a:flex-start p:3 pt:1].main>
			<div[fl:1]>
				<div.small> "{data.casts_count} lessons | {(data.casts_duration * 1000).format 'time'}"
				<div> "with {<b[fw:600]> teacher.name}"
			<img[w:44px h:44px rd:999px ml:auto] src=avatar loading="lazy" alt=teacher.name>
		<span[d:hflex a:flex-end p:1 3 fl:1]>
			<span[fl:1]> level
			<span.status>
		<.ribbon.ribbon-top-left> <span> "Pro" if data.access == "members"

tag course-progress-tile < course-tile
	prop context
	modules = []
	row

	css & 
		@before pt:0
	css self d:block w:100%
	css .card d:grid gtc:1fr 1fr min-height:150px rd:0
		rd:md w:1card inset:0 rotate:null scale:null bxs:sm pos:relative
	css .main@before content:none
	css self @hover .card
		.main y:0 
		.hoverboard o:1

	css .hoverboard h:100% w:100% d:vflex jc:center ai:center pos:absolute t:0 l:0
		o:0 zi:11 tween:styles ease-in-out .2s bgc:rgba(255,255,255,0.6)

	get course
		context

	get	art do
		course..art or ''

	get title do
		course..title or data.title or ''

	get modules-flat do
		unless modules and modules.length > 0
			unless context and context.items
				return modules

			context.items.forEach do(a,ai)
				unless a.items
					if course-type == 'tutorial'
						modules.push {cid:a.id, ci:ai}
					return
				let pls = a.items.forEach do(b,bi)
					unless b.items
						if course-type == 'course'
							modules.push {cid:b.id, ci:bi, pid:a.id, pi:ai, ptitle:a.title}
						return
					let scrims = b.items.forEach do(s,si)
						modules.push {cid:s.id, ci:si, pid:b.id, pi:bi, ptitle:b.title, mid:a.id, mtitle:a.title, mi:ai}
			# console.log 'flat packed', modules
		modules

	get get-row do
		row ||= modules-flat.find do $1.cid == data.id
		row or {}

	get lesson-nr
		get-row.ci + 1 or '-'

	get lesson-total do
		if course-type == 'tutorial'
			context and context.items.length or 'a lot'
		else
			modules.length

	get module-nr do
		get-row.mi + 1
	
	get module-title do 
		get-row.mtitle

	get playlist-nr do
		get-row.pi + 1

	get playlist-title do 
		get-row.ptitle

	get module-progress
		let mscrims = modules-flat.filter do $1.mid == get-row.mid
		let total = mscrims.length or 1
		let viewed = [] 
		let viewed-scrims = mscrims.map do(c)
			let s = store.viewings.find do(v)
				v.cid == c.cid
			viewed.push(s) if s

		Math.round((viewed.length / total) * 100)

	get course-type
		let id = context..items and context..items.length > 0
		# This should just be set as a field on the course
		if context..items and context..items.length > 0 and context.items[0]..id[0] == 'k'
			return 'path'
		if context..items and context..items.length > 0 and context.items[0]..id[0] == 'p'
			return "course"
		"tutorial"

	get modules?
		# console.log "modules?", context, context..items, context..items[0]
		if context..items and context.items[0]..id[0] == 'k'
			return yes
		no
		
	get progress do
		unless context..membership
			return 'enroll to see progress'
		Math.round(Math.ceil(context.membership.progress * 100))

	get link-title
		if course-type == 'path'
			"Module: {module-title}\nSection: {playlist-title}\nLesson: {data.title}"
		elif course-type == 'course'
			"Section: {playlist-title}\nLesson: {data.title}"
		else
			"{data.title}"

	def hydrate
		super
		innerHTML = ''


	<self .{data.type} .{tags}> <a.card href=data.contextualUrl(context) title=link-title>

		<.art[w:100%]>
			<art-pattern[w:100% rdl:xl h:100% bd:6px solid white] heading=title data=art>
			<.hoverboard>
				<svg src="svg/play.svg"  viewBox="0 0 60 60" width=60 height=60>


		<span[d:vflex a:flex-start p:3 pt:1 jc:space-between w:100% rdr:xl].main>
			if course-type == 'path'
				<div.small[mt:2 td:underline]> "Module {module-nr}: {module-title}"
				<div[m:1 0 w:80% mb:auto lh:1.1]> data.title
			elif course-type == 'course'
				<div.small[mt:2 td:underline]> "Section {playlist-nr}: {playlist-title}"
				<div[m:1 0 w:80% mb:auto lh:1.1]> data.title
			elif !(module-nr and lesson-total)
				<div[m:1 0 w:80%]> data.title
			else
				<div.small[mt:2 td:underline]> "Lesson {lesson-nr} of {lesson-total}"
				<div[m:1 0 w:80% mb:auto lh:1.1]> data.title
			
			<div[w:100%].small>
				<div[w:100%; h:1px; bgc:gray5;pos:absolute t:0 l:0]>
				<div[w:{progress}%; h:1px; bgc:$night;pos:absolute t:0 l:0 zi:1]>
				if context..membership
					<span> "{<strong> "{progress}%" } of course completed"
				else
					<span> "Enroll to see your progress"
