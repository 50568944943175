extend tag element

	set md value
		if $md =? value
			innerHTML = String(value).htmlify!

	set data data
		if _data =? data
			let id = data && data.id
			id ? setAttribute('data-id',id) : removeAttribute('data-id')
	
	get data
		_data

	get focin?
		contains(document.activeElement)
		
	def end$ v
		#afterVisit(v)

extend tag component
	def hydrate
		if dataset.id
			_data = store.get(dataset.id)
		self

extend tag script
	attr scenario_id
extend tag video
	attr autoplay
	attr muted
	attr loop
	attr playsinline

tag app-setting
	name

tag app-toggler
	value = true

	def exec
		data = !data
		render!

	def render
		<self .checked=(!!data) @click.stop.prevent=exec> <slot>

tag app-tab
	name

	def exec
		data = name
		render!

	def render
		<self .checked=(data == name) @click.stop.prevent=exec> <slot>