import './dialog.imba'
import './course-tile'

tag bootcamp-success-modal < app-dialog
	css 1w:min(90vw, 600px)
	css 1t:5vh
	css section my:4

	def hydrate
		innerHTML = ''
		schedule!
		render!

	def mount
		setTimeout(&,2000) do
			return unless store.subscription and store.subscription.type == 'bootcamp'
			const sale-date = new Date(store.subscription.created_at)
			const hours-since-sale = (new Date() - sale-date) / (1000 * 60 * 60)
			return unless time-since-sale < 24 # Only report within 24 hours

			const payload = 
				transaction_id: store.subscription.id
				value: store.subscription.sum
				currency: "USD"
				coupon: store.subscription.coupon
				items: [
					item_id: "{store.subscription.type}-{store.subscription.interval}"
					item_name: "{store.subscription.type}-{store.subscription.interval}"
					index: 0,
					price: store.subscription.sum
					quantity: 1
				]

			window.gtag "event", "purchase", payload

	def body
		let name = ''
		name = " {name}!" if store..me..user..first_name

		<>
			<header[mb:3 bgi:url('/static/art/bootcamp-stamp.png') bgp:top right bgs:50px bgr:no-repeat]> 
				<.intro[w:65% mb:8]>
					<h3[ta:left us:none]> "Welcome onboard{name}! 🎉"
					<p> "We are super excited to bring you onboard this deeper experience where you can study with a small group and get direct feedback from our teachers and code reviewers."
					<p[mt:4]> "We've added new details to your dashboard to help you get started and access your virtual meetups, study group, and code reviews."
			<main>
				<h4> "With Scrimba bootcamp you have access to:"
				<section>
					<p.bold> "Weekly meetings"
					<p> "Weekly meetups led by an experienced developer."
				<section>
					<p.bold> "Study group"
					<p> "Your dedicated study group channel on discord. Say hello, share your progress, ask questions - the group is there to support, discuss, and share code with."
				<section>
					<p.bold> "Code Reviews"
					<p> """You can now ask for reviews of your Solo Projects. Go to the 
						{<a[fs:4] href="https://discord.com/channels/684009642984341525/959015608861749252"> "#code-reviews"}
						channel on discord to learn how to use it."""
				<section>
					<p.bold> "Expect an email soon!"
					<p> """If you don't already get Scrimba emails, sign up   
						{<a[fs:4] href="https://scrimba.ck.page/signup"> "here"}
						to make sure you get important emails about the Bootcamp."""

				<[d:grid gaf:column gcg:1rem jc:start]>
					<a[cursor:pointer].button.white.sm @click=close> "Close"
