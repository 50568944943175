import './dialog'

tag sign-up-modal < app-dialog
	action = "/auth/signup?from={redirect-path}"
	get redirect-path do store.url.pathname == '/' ? '/dashboard' : store.url.pathname

	css $box max-width:23rem

	def body
		if window..flags..contains('mod-embedded')
			if window.flags.contains('mod-guest')
				return <.group-head[my:3 c:gray6]> <p> "To sign up, go to { <a.link[fs:inherit] href="https://scrimba.com" target="_blank"> "scrimba.com" }"
			else
				return <.group-head[my:3 c:gray6]> <p> "You already have an account"

		<>
			<p[d@guest:none].alert.danger> "You are already logged in - continue to log in with another account."
			# <header[mb:3]> <h1[ta:center fs:lg]> "Sign up"
			<a.button[bg:blue2 @hover:blue3 c:blue8 $bc:blue5 w:100%] href="/auth/github?from={store.url.pathname}"> "Sign up with GitHub"
			<.group-head[my:3 c:gray6]> <p.small> "or register with email"
			<div[d:vgrid g:2]>
				<input type='email' name='email' placeholder='Email' autocomplete='email' required>
				<input type='text' name='name' placeholder='Name' autocomplete='given-name'>
				<input type='password' name='password' autocomplete="new-password" placeholder='Password' minLength="8" required>
				<button.button[w:100%] type='submit'> "Sign up"
			<footer$footer>
				<span> "Have an account? {<a.link.small href="#sign-in"> "Log in"}"
				<a.small.link[ml:auto] href="#forgot-password"> "Forgot password?"


	def completed res, event
		if res.code != 200
			setTimeout(reload, 3000)
			return

		const page_url = window.location.href.indexOf('#') > -1 ? window.location.href.split('#')[0] : window.location.href
		api.track('Users','Signup', page_url)

		# NOTE: This will enroll the new user even if the course is pro only. The tradeoff is acceptable
		# given the complexity of tracking this through a payment flow and then enrolling afterwards.
		if store.course..id
			await window.$api.post("/course/{store.course.id}/enroll")
			await window.reloadUserData!
			reload!

		if res.data..redirect_to
			let url = new URL(res.data.redirect_to, window.location.origin)
			window.location = url.href