import * as Sentry from "@sentry/browser";

Sentry.init({
	dsn: "https://a0e04596f6b22fab91ccc2dceb1ac2ed@o306713.ingest.us.sentry.io/4506932202897408",
	integrations: [],
})

global.captureException = def captureException e, context = {}, tags = {}
	# e = new Error(e) unless e isa Error
	try
		# tags are searchable
		let user = {}
		try user = JSON.parse(window.localStorage.getItem("me")).user

		Sentry.setTags(tags)
		context.stack = e.stack
		Sentry.captureException e, {extra: context, user}
	catch error
		console.warn "Error in capture exception!", error, e