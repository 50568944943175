import './dialog.imba'
import './course-tile'

tag pro-success-modal < app-dialog
	css 1w:min(90vw, 600px)
	css 1t:5vh
	css section my:4

	def hydrate
		innerHTML = ''
		schedule!
		render!

	def mount
		setTimeout(&,2000) do
			return unless store.subscription and store.subscription.type == 'pro'
			const sale-date = new Date(store.subscription.created_at)
			const hours-since-sale = (new Date() - sale-date) / (1000 * 60 * 60)
			return unless time-since-sale < 24 # Only report within 24 hours

			const payload = 
				transaction_id: store.subscription.id
				value: store.subscription.sum
				currency: "USD"
				coupon: store.subscription.coupon
				items: [
					item_id: "{store.subscription.type}-{store.subscription.interval}"
					item_name: "{store.subscription.type}-{store.subscription.interval}"
					index: 0,
					price: store.subscription.sum
					quantity: 1
				]

			window.gtag "event", "purchase", payload

	def body
		let name = ''
		name = " {name}!" if store..me..user..first_name

		<>
			<header[mb:3 bgi:url('/static/art/pro-stamp.png') bgp:top right bgs:100px bgr:no-repeat min-height:100px]> 
				<.intro[w:65% mb:8]>
					<h3[ta:left us:none]> "Welcome onboard{name}! 🎉"
					<p> "We are super excited to bring you onboard with the career path, Discord community, and all courses."
			<main>
				<h4> "With Scrimba pro you have access to:"
				<section>
					<p.bold> "Frontend Developer Career Path"
					<p> "Your path to nail the job interview and become a frontend developer. Tutorials, coding challenges, and real-world projects. {<a href="/learn/frontend"> "Get started"}."
				<section>
					<p.bold> "Scrimba Discord community"
					<p> "Say hello, share your progress, ask questions - and get your pro badge! {<a href="/discord" target="_blank"> "Join the Scrimba Discord community"}."
				<section>
					<p.bold> "All pro courses"
					<p> "With access to everything, you can find the perfect fit for you from {<a href="/allcourses"> "all Scrimba courses"}."

				<[d:grid gaf:column gcg:1rem jc:start]>
					<a[cursor:pointer].button.white.sm @click=close> "Close"
