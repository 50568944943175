import '../shared/sentry'
import '../shared/element'
import '../widgets/change-subscription-modal'
import '../widgets/cancel-subscription-modal'
import '../widgets/course-tile'
import '../widgets/find-course-modal'
import '../widgets/github-education-modal'
import '../widgets/no-github-education-modal'
import '../widgets/snackbar'
import '../widgets/navbar-actions'
import '../widgets/site-header'
import '../widgets/dialog'
import '../widgets/sign-in-modal'
import '../widgets/sign-up-modal'
import '../widgets/join-modal'
import '../widgets/forgot-password-modal'
import '../widgets/reset-password-modal'
import '../widgets/user-delete-modal'
import '../widgets/bootcamp-modal'
import '../widgets/navbar-announcement'
import '../widgets/completed-course-dialog'
import '../widgets/sign-up-success-modal'
import '../widgets/pro-success-modal'
import '../widgets/bootcamp-success-modal'
import '../widgets/scrim-modal'
import '../widgets/teams-modal'
import '../widgets/teams-scrim-modal'
import '../widgets/env-modal'
import '../widgets/edit-slides-modal'

let modals = {}
let modal = null
const api = window.$api

# NOTE:
# We have modals and dialogs. Modals are anchor tag based. Dialogs are mounted directly.
# All modals are listed in the array below.
# Dialogs are mounted directly in the DOM.
# 
# Both modals and dialogs must follow these steps:
# 1) Add a new file ending in `-modal.imba` in `src/widgets/`, like `src/widgets/bootcamp-modal.imba`.
# 2) Import that file above.
#
# Then, if you're making a modal, follow these steps:
# 3) Add the name (NOT ending with `-modal`) to the array below.
# 4) Add an anchor link to the name (NOT ending with `-modal`), like `<a href="#bootcamp"> "Join now"`.
#
# Or, if you're making a dialog, follow these steps:
# 3) See structure of `src/widgets/completed-course-dialog.imba`.
# 4) Mount the dialog with `imba.mount <completed-course-dialog course=course>`.

const every-scrimba-modal = [
	'sign-in'
	'sign-up'
	'sign-up-success'
	'forgot-password'
	'reset-password'
	'cancel-subscription'
	'change-subscription'
	'find-course'
	'github-education'
	'no-github-education'
	'join'
	'pro-success'
	'user-delete'
	'bootcamp'
	'bootcamp-success'
	'env'
	'edit-slides'
	'hi-there-frontend-focus'
	'teams'
	'teams-scrim'
]

def syncLocationHash
	const hash = document.location.hash.slice(1)
	const prev = api.hash or ''

	if api.hash =? hash
		# console.log 'changed',prev,hash
		if modal and modal.id === prev
			modal.close!

		if hash in every-scrimba-modal
			modal = modals[hash] ||= <{hash}-modal id=hash>
			imba.mount(modal)
			modal.open!
		else
			modal = null

window.addEventListener('hashchange') do(e) syncLocationHash!
syncLocationHash!

window.addEventListener('showmenu') do(e)
	if window.customElements.get(e.detail.type)
		imba.mount(<{e.detail.type} data=e.detail.data origin=e>)

window.addEventListener('open') do(e)
	# console.warn 'open!',e.detail,e,e.detail.branch
	try
		let link = e.target.closest('a[href]')
		if e.originalEvent
			e.originalEvent.preventDefault!
			link ||= e.originalEvent.target.closest('a[href]')
	
		if e.detail isa Scrim and e.detail.branch
			# console.log 'enter branch!!',e.detail.branch
			e.detail.branch.enter!
		else
			let url = e.detail.contextualUrl(store.course or store.playlist)
			document.location.href = url
			# link ? link.href : e.detail.contextualUrl(store.course or store.playlist)
		# window.store.menuFocus = null
		imba.commit!
	catch e
		console.warn 'error',e
	# if let typ = window.customElements.get(e.detail.type)
	#	let dialog = <{e.detail.type} data=e.detail.data origin=e>
	#	imba.mount(dialog)

# append stripe
def loadStripe
	let script = document.createElement('script')
	script.type = 'text/javascript'
	script.src = 'https://js.stripe.com/v3/'
	script.onload = do window.$emit('stripe',null,Stripe)
	document.body.appendChild(script)

loadStripe!