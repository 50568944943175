import { welcome-scrim, karma-notion-page, weekdays } from '../helpers'

let navbar-post

tag navbar-actions
	css .avatar mx:4 rd:sm h:8 w:8 bd:1px solid white va:top box-sizing:border-box
		bg:$avatar bgs:cover

		@small-header,@page-scrim h:5 w:5 bd:none mr:0

	css &
		.menu, .browse, .view
			pos:fixed @450:absolute t:10% @450:100% r:2.5vw @450:0 zi:9000 bg:white bxs:0 3px 25px rgba(0,0,0,.25) w:min(95vw, 300px) py:2 px:2 rd:lg mr:0 @450:1
			visibility:hidden o:0 tween:all 0.2s linear
			.item td:none min-width:230px d:hgrid py:2 px:4 fw:500 cursor:pointer rd:md 
				c:gray7 tween:all .1s linear
				@hover bg:gray6/7 c:gray8 tween:all .1s linear


		.me @focin .menu visibility:visible o:1 tween:opacity .3s linear
		.me @focus .menu visibility:visible o:1 tween:opacity .3s linear

		.courses @focin .browse visibility:visible o:1 tween:opacity .3s linear
		.courses @focus .browse visibility:visible o:1 tween:opacity .3s linear

		.bootinfo fs:1.25rem cursor:pointer us:none p:0 6 0 0 m:0 6 0 0 border-right:solid 1px rgb(204, 211, 221)
		.bootinfo @focin .view visibility:visible o:1 tween:opacity .3s linear
		.bootinfo @focus .view visibility:visible o:1 tween:opacity .3s linear

	css .karma bg:yellow4 c:yellow7 ml:2
		h:6 d:flex ai:center px:2 rd:100
		ts:1px 1px 0 white/10
		bd:1px solid #efc43e fs:xs
		cursor:default


	def hydrate
		innerHTML = ''
		schedule!
		render!

	def setup
		return unless web?
		window.$once('userdata').then do
			if const p = store.me..navbar-post
				const key = "p:{p.id}:seen"
				unless window.localStorage.getItem(key)
					navbar-post = p

	def search query
		document.location.href = "/search?q={query}"

	def logout
		window.localStorage.setItem('me','{}')
		window.posthog.reset() if window.posthog

	def reload
		location.reload!

	def connectDiscord
		window.open("/discord/connect")
		window.addEventListener 'focus', reload

	get browse-courses
		<[d:none @450:block @page-scrim:none @page-allcourses:none fs:1.25rem cursor:pointer us:none pb:4 mb:-4].courses tabIndex=-1>
			"Courses"
			# <svg-user[w:4 h:4]>
			<.browse>
				<a.item href="/allcourses"> "All courses"
				<a.item href="/learn/aiengineer"> "AI Path"
				<a.item href="/learn/frontend"> "Frontend Path"
				
				<.group-head[c:gray5 my:2]>
				<p[cursor:default px:4 fs:1rem c:gray6 mb:0 pb:0]> "Not sure where to start?"
				<a.item [pt:0 ] href="#find-course"> "Let us help you find your perfect course."

	def browse-bootcamp
		<[d:none @bootcamp:block @page-scrim:none @page-allcourses:none].bootinfo tabIndex=-1>
			<[d:hflex jc:center ai:center]>
				<img[w:100% h:100% max-width:40px max-height:40px mr:2.5] src="/static/art/bootcamp-stamp.png">
				<p> "Bootcamp"
			
			# NOTE: The logic below is messy, as the styling changes based on the two groupings of events. These would be nice to clean up:
			# - Display when there are student events today, but all are done? Right now there's a time window.
			# - Name of section when there are only events tomorrow? Right now they are skipped.
			<.view[p:20px w:375px]>
				if bootcamp-student-events-today.length > 0
					let two-sections = 24px
					two-sections = 0 if bootcamp-support-events.length == 0
					<h5[p:0 mb:0.8em mt:two-sections]> "Support sessions"
					for item in bootcamp-student-events-today
						<.item[p:0 my:0.5em mx:0 transition:none]> render-item(item)

				if bootcamp-support-events.length > 0
					let two-sections = 24px
					two-sections = 0 if bootcamp-student-events-today.length == 0
					<h5[p:0 mb:0.8em mt:two-sections]> "Weekly sessions"
					for item in bootcamp-support-events
						<.item[p:0 my:0.5em mx:0 transition:none]> render-item(item)

				<p[p:20px 0 0 0 m:26px 0 0.25em 0 border-top:dotted 1px rgb(197, 207, 222)]> "Study group"
				<p.small[p:0]> "Collaborate with peers in your dedicated { <a.small href="https://discord.com/channels/684009642984341525/959014685586382879"> "#study-group" } channel."
				<p[p:0 m:0.5em 0 0.25em 0]> "Code reviews"
				<p.small[p:0]> "Submit projects for review using the { <code> "/review" } command in your { <a.small href="https://discord.com/channels/684009642984341525/959015608861749252"> "#code-reviews" } channel"
	
	get sub-age
		return #sub-age if #sub-age
		const today = new Date!
		const sub_date = new Date(store.subscription.created_at)
		#sub-age ||= Math.round(today - sub_date) / (1000 * 60 * 60 * 24)

	# Show annual promo if user is a subscriber on a monthly plan and he has been a subscriber for more than 32 days
	get show-annual-promo?
		#show-annual-promo ||= store.subscription and store.subscription.type == 'pro' and store.subscription.processor == 'stripe' and store.subscription.interval == 1 and sub-age > 32

	get free-user?
		#free-user ||= store.subscription ? no : yes

	get show-bootcamp-promo?
		#show-bootcamp-promo ||= store.subscription and store.subscription.type == 'pro' and store.subscription.interval > 1 and sub-age > 32

	days = ['Mon','Tue','Wed','Thu','Fri','Sat','Sun']
	months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec']
	per_page = 4
	page_nr = 0
	infoZones = ['America/Los_Angeles', 'America/Denver', 'America/New_York', 'Europe/London', 'Europe/Berlin','Europe/Istanbul', 'Asia/Karachi', 'Asia/Kolkata', 'Asia/Singapore', 'Asia/Tokyo', 'Australia/Sydney', 'Pacific/Auckland']
	colors = {
		'free': 'var(--medium-moss)'
		'pro': 'var(--light-baby)'
		'meet and greet': 'rgb(235, 187, 124)'
		'live stream': 'var(--lemony)'
		'bootcamp': 'var(--bubble-gum)'
		'coding challenge': 'var(--medium-bubble-gum)'}
			
	titles = {
		free: 'This event is for all scrimba users'	
		pro: 'This event is for Scrimba pro members only'		
		'meet and greet': 'This is a social event to meet community members'
		'live stream': 'Tune in to a broadcast from the Scrimba team'
		'coding challenge': 'Practice your skills by solving challenges'}

	images = {
		
	}

	collection = store..me..events..filter(do $1.labels.find(do $1 == 'Bootcamp')) or []

	css $bgcolor:$medium-crab-cake
	css event-widget mt:100px @1140:inherit 
	css $collection d:grid grid-gap:1rem ac:flex-start
	css img rd:10px size:90px
	css .frame h:auto
	css .event p:0
		bgc@hover:hsl(26.1, 53.5%, 96.6%)
		box-shadow@hover:0 0 0 8px hsl(26.1, 53.5%, 96.6%)
		rd:2px
	
	css .labels fs:sm fw:400 mt:1
		span p:1 2 rd:4px mr:2
	css .title fw:600 fs:md 

	css .cal d:flex ai:center cursor:pointer td:none c:inherit
		
	get headline do "Upcoming events"
	get item-height do 'auto'

	get event_page do items.slice(page_nr * per_page, (page_nr + 1) * per_page)
	get user_time do #user_time ||= Intl.DateTimeFormat().resolvedOptions()
	get user_timezone do user_time.timeZone
	get user_locale do user_time.locale

	def fmtdate start_time
		let date = new Date(start_time)
		"{days[date.getUTCDay()]}, {months[date.getUTCMonth()]} {date.getUTCDate()} "

	def fmttime time, duration
		let start = output_time time
		let end = end(time, duration)
		"{start} - {end} UTC"

	def output_time time
		let date = new Date(time)
		let minutes = date.getUTCMinutes! < 10 ? "0{date.getUTCMinutes!}" : date.getUTCMinutes!
		let hours = date.getUTCHours!
		"{hours}:{minutes}"

	def end time, duration
		let start_time = new Date(time)
		let date = start_time.getTime() + duration * 60000
		output_time(date)

	def user_formated_date date
		let options = weekday: 'short', month: 'short', day: 'numeric'
		new Intl.DateTimeFormat(user_locale, options).format(date)

	def user_formated_time date, duration
		let startDate = new Date(date)
		let endDate = new Date(startDate.getTime() + duration * 60000)
		
		let from-hour = startDate.getHours()
		let from-minute = date.getMinutes() == 0 ? '' : ":{date.getMinutes()}"
		
		let to-hour = endDate.getHours()
		let to-minute = endDate.getMinutes() == 0 ? '' : ":{endDate.getMinutes()}"
		let ampm = to-hour >= 12 ? "PM" : "AM"
		
		"{from-hour % 12}{from-minute} - {to-hour % 12}{to-minute} {ampm}"

	def user_formated_timezone date
		let t = new Intl.DateTimeFormat(user_locale, timeZoneName: 'short').formatToParts(date)
		let tz = t.find do $1.type == 'timeZoneName'
		tz.value

	def title t
		titles[t.toLowerCase!.trim!] or ''

	def color t
		colors[t.toLowerCase!.trim!] or 'var(--lemony)'

	def calc-time-until event-time\Date
		const now = new Date().setHours(0,0,0,0)
		const next = new Date(event-time).setHours(0,0,0,0)
		const is-today = now == next
		const is-tomorrow = now + 86400000 == next
		const difference = new Date(event-time).getTime! - Date.now!
		{is-today, is-tomorrow, difference}

	get bootcamp-student-events-today
		const now = new Date()
		const day = now.getDate()
		const month = now.getMonth()

		return collection.filter do 
			return no unless $1.labels.find(do(l) l == 'Bootcamp')
			return no unless $1.labels.find(do(l) l == 'Student Session')

			const time = new Date($1.when)
			return time.getDate() == day and time.getMonth() == month
		
	get bootcamp-support-events
		# Filter events that have the label 'Bootcamp' but NOT 'Student Session', and limit to 2
		const supportLimit = 2
		const events = collection.filter do
			return no unless $1.labels.find(do(l) l == 'Bootcamp')
			return no if $1.labels.find(do(l) l == 'Student Session')
			return yes
		
		return events.slice(0, supportLimit)

	def render-item item
		const { is-today, is-tomorrow, difference } = calc-time-until item.when
		const time = new Date(item.when)

		<.event>
			<a.cal target='_blank' href=item.link title=item.description> 
				<img[w:60px h:100% mr:10px rd:5px bgc:{item.color}] src=item.asset>
				<p[p:0 fs:1rem fw:bold w:100%]>
					if item.title.startsWith('Scrimba Bootcamp: ')
						item.title.slice(18)
					else
						item.title
					<span.small[d:flex fs:0.9rem mt:8px fw:initial]>
						<svg src="svg/cal.svg" [w:16px h:auto mr:2]>
						<span> "{user_formated_time(time, item.duration)} {user_formated_timezone(time)}"
						<span[fw:600 ml:auto pos:relative]>
							if is-today
								if difference > 0
									<span> "Today"
								else if difference > -3600000
									<svg[pos:absolute zi:10 top:-20 l:-31.5 scale:0.26 rotate:-10deg] src="svg/hand-circle.svg">
									<span> "Live now"
								else
									<span> "Earlier today"
							else if is-tomorrow
								<span> "Tomorrow"
							else
								<span> user_formated_date(time)

	def render
		<self>
			<div[d:none @guest:contents]>
				# mobile
				<[d:none @lt-sm:contents]>
					browse-courses
					<a[d:none @page-course:initial mx:4].button.text href="/pricing"> "Pricing"
					# <a[d@page-course:none mx:4].button.sm href="#sign-up"> "Create Account"
					<a[d@page-course:none d@page-allcourses:none mx:4].button.sm href="/allcourses"> "Courses"
					<a.button.text href="#sign-in"> "Log in"

				# desktop
				<[d:none @sm:contents]>
					if navbar-post
						<navbar-announcement[d:none @member:block] post=navbar-post>
					<a.button.text[mx:4 d:flex ai:center] href="/teams">
						<span> "For Teams"
					<a.button.text[mx:4 d:flex ai:center] href=welcome-scrim>
						<span> "Why Scrimba?"
					browse-courses
					<a[d:none @page-course:initial mx:4].button.text href="/pricing"> "Pricing"
					<[d@page-course:none]>
						<a[d@sticky-header:none mx:4].button.text href="/pricing"> "Pricing"
						<a[d:none @sticky-header:initial mx:4].button.sm href="#sign-up"> "Create Account"
					<a.button.text href="#sign-in"> "Log in"

			<div[d:none @user:contents]>
				if navbar-post
					<navbar-announcement[d:block d@guest:none] post=navbar-post>
				
				if free-user?
					<a.button.rounded.sm[d@subscriber:none mr:4 d@page-pricing:none] href='/pricing'> "Go Pro!"
				elif show-annual-promo?
					<a.button.rounded.sm[mr:4 d@page-pricing:none d@page-scrim:none d@page-bootcamp:none] href='/mybilling'> "Save with annual!"
				elif show-bootcamp-promo?
					<a.button.rounded.sm[mr:4 d@page-pricing:none d@page-scrim:none d@page-bootcamp:none] href='/pricing#bootcamp'> "Join Bootcamp!"

				<a [c:$night td@hover:none d:none @475:block @page-scrim:none @page-allcourses:none @page-bootcamp:none @bootcamp:none fs:1.25rem us:none pb:4 mb:-4 mr:4] href="/bootcamp"> "Bootcamp"
				browse-bootcamp!

				browse-courses

				<[d:block pb:4 mb:-4].me tabIndex=-1>
					<div[d:flex fld:row ai:center cursor:pointer]>
						<.avatar alt="avatar">
					<.menu[fs:md]>
						<span.item[d:hflex jc:space-between ai:center cursor@hover:default bg@hover:inherit]>
							<p> "Karma"
							<div[d:flex]>
								<span[c:$night]> store.me..karma
								<svg[scale:50% mt:-1px] src="svg/karma.svg">
						<.group-head[c:gray5 my:2]>
						<a.item href="/dashboard#overview"> "Dashboard"
						if store..user..discord_id
							<a.item href="/discord" target='_blank'> "Open Discord"
						else
							<a.item href="#" @click.prevent=connectDiscord href="/discord/connect" rel="nofollow"  target='_blank'>  "Connect to Discord"
						<a.item[mr:4 d:none @!450:block] href="/allcourses"> "Find a course"
						<a.item href="/settings"> "Settings"
						<a.item href="/new"> "New scrim"
						<.group-head[c:gray5 my:2]>
						<a.item href="/auth/logout" @click=logout> "Log out"
