import './app-logo'
import './navbar-actions'
import './site-menu'
import {share} from '../services/twitter'

tag PathItem
	level = 0
	context = null
	hovertimeout = null
	hoverdata = null
	loader = null

	css d:hflex a:center cursor:pointer as:stretch

	css c:gray6
		fs:md/1 @page-scrim:sm-/1
		@page-scrim c:white/50 .current:white

	css .title
		ws:nowrap of:hidden text-overflow:ellipsis

	css a td:none c:inherit @hover:$linky
		@page-scrim c@hover:$linky

	css .show-menu > $item c:$linky @page-scrim:white

	css $sep
		fl:0 0 auto
		suffix: "/"
		mx:1 @!700:0.3
		o:0.5
		c:black @page-scrim:gray5 pe:none
		@before content: " " pos:absolute d:block h:100% w:10 l:0 ml:-10
			bg:linear-gradient(to right, clear,blue5)
			d:none
		# c:blue6

	css $next fl:0 1 auto of:hidden

	css $item
		d:hflex a:center as:stretch p:1 rd:sm fl:0 200 auto of:hidden min-width:40px ws:nowrap

	css svg w:3 h:3 va:top
		>>> path stroke:4px

	def hover e
		let m = document.getElementById('menu')
		if m.showing?
			m.select(data)
		elif !hovertimeout
			hovertimeout = setTimeout(&,300) do
				document.getElementById('menu').select(data)

	def hoverclear e
		clearTimeout(hovertimeout)
		hovertimeout = null

	def open e
		document.location.href = data.url

	get name
		<.title.small[d:block py:1]> data.short_title or data.title or data.name

	def render
		let curr = store.menuFocus
		<self .{data.$type} .{data.type} .current=(!data.focus) .show-menu=(curr == data)>
			<$sep>
			<a[all:unset] href=data.url> <$item @pointerenter=hover @pointerleave=hoverclear id="{data.id}-menu-origin">
				name
			if data.focus and data.focus != data and level < 10 and (!data.focus.released_at or data.focus.released_at < new Date())
				<PathItem$next data=data.focus context=data level=(level + 1)>

tag BasePath < PathItem

	css $sep d:none
	css $item fw:600
		fs:xl/1 @page-scrim:sm-/1
		c:black @page-scrim:inherit

	# css .tilde
	# 	fs:34px lh:14px top:-2px fl:0 0 30px min-width:initial ta:center ja:center

	get name
		'scrimba' # '~'

	def render2
		let curr = store.menuFocus
		<self .{data.$type} .{data.type} .current=(!data.focus) .show-menu=(curr == data)>
			<a[all:unset] href=data.url> # <$item.tilde @pointerenter=hover> "~"
			if data.focus and data.focus != data and (!data.focus.released_at or data.focus.released_at < new Date())
				<PathItem$next data=data.focus context=data level=(level + 1)>

tag site-header

	css d:hflex pos:fixed t:0 l:0 r:0 px:4 zi:800 a:center h:1hdh fs:md c:$night bg:transparent
		.logo pe:auto c:black @page-scrim:white
		.actions d:contents
		.offer d:block @!660:none ja:center ml:2 td:none ws:nowrap fl:0 0 auto
			c:white rd:20px px:3 py:2 fs:sm/1 mr:4 ml:1
			bg:$linky
			tween:styles 0.1s quart-out
			@hover scale:1.05 rotate:1deg

		$path >>> .next d:none pe:none # d:hflex a:center fld:row-reverse pl:2

		@page-scrim
			px:1 bg:#4a5667 fs:sm/1.2 c:white
			$path >>> .next d:hflex pe:auto
			.offer px:2 py:1 fs:xs/1


		@before
			pos:absolute inset:0 bg:white/95 pe:none o:0 bxs:0px 0px 10px black/10 content:" " d:block
			tween:all 0.2s cubic-out

		@sticky-header
			$path >>> .next d:hflex pe:auto
			@before o:0.99


	get path
		store..path or []


	get logoUrl
		store.user ? '/dashboard#overview' : '/'

	def hydrate
		innerHTML = ''
		schedule!
		render!

	def topItem
		if $web$
			let top = store.home
			while top.focus
				top = top.focus
			return top # implicit return should return top already, but what the heck!

	def persistIfLocal item
		return item.id unless item.mine? and item.local?

		const title = if item.$parent..gid != 'gjavascriptmas'
			item.branch.title!
		else
			const day = data.$parent.metadata..share..day or 'x'
			item.branch.title!.indexOf('Note at ') == -1 ? item.branch.title! :  "Solution for day {day} of #javascriptmas"
		await item.branch.persist!
		let id = item.branch.id!
		await api.post "/scrims/{id}",
			title: title
			access: 'guests'
			privacy: 'secret'
		return id

	def sharing data, note
		let id = await persistIfLocal(note)
		let shareuri = "https://scrimba.com/scrim/{id}"
		share(data, shareuri)

	def render
		<self>
			if web?
				<site-menu$menu#menu>
			<a.logo[td:none d:hflex a:center h:8] href=logoUrl title="home">
				<app-logo[d:inline-block h:14px m: 0 1 0 2]>
				# <span.logotype[d@lt-sm: none]> "scrimba"
			if web?
				<BasePath$path[max-width:80vw min-width:87px of:hidden d:none @370:flex @guest:none @guest@600:flex] data=store.home>
			if let git = store..scrim..metadata..git
				<a.link href=git.url [d:none]=!git.url target="_blank">
					css
						d:hflex ai:center td@hover:none
						$ghc:white text-transform:capitalize py:2px
						bd:0.5px solid $dark-grey rd:19px ml:4 fs:12px c:$dark-grey @hover:$ghc
						bc@hover:$ghc tween:all .2s
						px:12px p@!418:2
						svg size:12px
						svg.lk d:none @418:block
						span d:none @418:block
					if git.source == 'github.com'
						<svg.vcs src="svg/github.svg">
					else
						<svg.vcs src="svg/gitlab.svg">
					<span[mx:2]> "{git.source.replace('.com', '')} repo"
					<svg.lk src="svg/external-link.svg">
			if $web$ and store..scrim..metadata..share
				let top = topItem!

				if top..type == 'note' #  <- in a note
					let note = top
					# persisted?
					<a.button.sm @click.debounce(1000).prevent=sharing(store.scrim.metadata.share, note) [d:inline-block bgc:$white c:$night fw:bold ml:3]> 'Share solution!'
				# elif home..focus..focus..focus
				# Trying to share non-branch -> Branch? 
				# <a.button.sm @click.prevent=share(store.scrim.metadata.share) [d:inline-block bgc:$white c:$night fw:bold ml:3]> 'Share solution!'
				
			<div[fl:1 min-width:0 @505:8]>

			<navbar-actions.actions>
