
const PrivacyOptions =
	private: ["Private","Only you"]
	public: ["Public","Anyone can access"]
	unlisted: ["Unlisted","Public but not searchable"]

const AccessOptions =
	owner: ["Private","Only you"]
	guests: ["Secret","Anyone with link"]
	users: ["Members","Only logged in users"]
	members: ["Subscribers","Only Pro members"]

tag playlist-edit-dialog < app-dialog
	payload = {}
	cb = do(res)
	mode = 'insert' # 'swap', 'insert' 
	editScrims = no
	showScrims = no
	

	css 1w:600px w:1w l:calc(50% - 0.5w) t:200px zi:2010 pos:fixed
		$box min-width:550px
		
	css label d:vflex ja:stretch
		> div c:gray6
		> input@checked + div
			c:gray9

	css .courses
		d:hgrid of:hidden rd:md bd:3px solid gray3 bg:gray3

	css .option
		d:vflex ja:center py:2 rd:md tween:styles 0.2s quart-out
		span c:gray6
		c:gray6 @hover:gray7 .checked:gray8
		bg:clear @hover:white/20 .checked:white

	def mount
		action = data.url
		data.items = data.items.filter(do !$1.archived_at)

		payload = {
			title: data.title
			privacy: data.privacy
			description: data.description or ''
		}
		setTimeout(&,0) do flags.add('show')
	
	get formData
		payload.item_ids = data.items.map do $1.id
		payload
	
	get scrims
		store.scrims or []

	def completed res
		console.log "completed. patching data"
		console.log res
		cb(res)
		close!

	def failed res,e
		console.log 'failed', res,e
		if res..message 
			imba.mount <app-snackbar data=res>, 
			imba.commit!


	def add item
		data.items.push(item)

	def remove index
		data.items.splice(index,1)

	def body
		<div[h:100% d:vflex g:2rem]>

			# header buttons
			<div>
				<div.header-tabs[d:flex g:1 fls:1]>
					css button m:0
					<button.button.sm[mr:4] @click.prevent=(editScrims = false) .neutral=editScrims> "Edit Metadata"
					<button.button.sm[mr:4] @click.prevent=(editScrims = true) .neutral=!editScrims> "Edit Scrims"


			# body content
			css .body h:100% ofx:scroll overscroll-behavior-y:contain

			if editScrims
				<div.body [min-height:100px border:1px solid gray4 rd:lg]>
					<scrim-editor scrims=data.items @remove=(remove(e.detail.index)) >
			else
				<div.body[bdt:1px solid gray2 border-bottom:1px solid gray2 py:2rem]>
					<input[mb:3 ] placeholder='Title' bind=payload.title>
					<textarea placeholder='Description' bind=payload.description>

					<.group-head[my:3 c:gray6]> <p> "Privacy"
					<div[d:vgrid g:2]>
						<div.courses> for own value,desc of PrivacyOptions
							<button.option .checked=payload.privacy==value bind=payload.privacy value=value type='button' >
								<b[mr:1 tt:capitalize fw:600]> desc[0]
								<span[fs:xs]> desc[1]

			# footer buttons
			<div[d:flex jc:flex-end ai:flex-start w:100%]>
				if editScrims
					<div[ml:0 d:hflex pos:relative zi:10].button.sm.dropdown.autoshow> "Add scrims"
						<.menu[bgc:white border:1px solid black/15 l:0px w:max-content b:0px pos:absolute max-height:30vh ofy:auto ofx:hidden h:30vh t:initial zi:10] .show=showScrims>
							for item in scrims
								<.item @click=add(item)> item.title
					
				<div> <button.button.sm[mx:0] type='submit'> "Save Changes"


tag scrim-editor
	prop scrims = []

	# NOTE: Consider handling deleted scrims right in the playlist here. For now, we assume they are already filtered out.
	# get live_scrims
	# 	scrims.filter do !$1.archived_at
	# get dead_scrims
	# 	scrims.filter do $1.archived_at

	def handleSortStart i,item
		drag-index = i
		drag-item = item

	def handleSortDrop drop-index
		# handle dragging from above where removing the item will affect the target index
		const offset = drag-index <= drop-index ? 0 : 1
		scrims.splice(drag-index, 1)
		scrims.splice(drop-index + offset, 0, drag-item)
		resetValues()
	
	def handleSortOver e
		over-index = e.detail.index
	
	def handleSortEnd
		resetValues()
	
	def resetValues
		drag-item = null
		over-index = null
		drag-index = null

	drag-item = null
	drag-index = null
	over-index = null

	css d:vflex  

	def render
		<self>
			if scrims.length === 0
				<p.small[us:none pe:none max-width:100% overflow:hidden ta:center flex:1 d:flex ja:center]> "No Scrims Yet"
			else
				console.log 'scrims', scrims
				for scrim, i in scrims
					<sortable-row
						key=i
						scrim=scrim
						index=i
						over=(over-index === i)
						noEffect=(over-index === drag-index or over-index === (drag-index - 1))
						dragOrigin=(drag-index === i)
						@sortStart=(handleSortStart(i, scrim))
						@sortDrop=(handleSortDrop(i))
						@sortOver=handleSortOver
						@sortEnd=handleSortEnd
					>
				# NOTE: Consider handling deleted scrims right in the playlist here. For now, we assume they are already filtered out.
				# if dead_scrims.length > 0
				# 	<.group-head[my:3 c:gray6]> <p> "Deleted Scrims"
				# 	for scrim in dead_scrims
				# 		<code> scrim.title

tag sortable-row
	prop index\number
	prop over = false
	prop noEffect = false
	prop dragOrigin = false
	prop scrim

	get since
		return "Released {scrim.released_at.toTime!}" if scrim.released_at
		return "Created {scrim.created_at.toTime!}" if scrim.created_at
		return "Unknown creation and release time"

	def handleDragOver(e)
		emit('sortOver', {index, scrim})
	
	css d:flex jc:space-between ai:center px:4 bdb:1px solid gray3 @last:none bgc:white
		py:1 cursor:grab
		# &.over bgc:gray2
		&.hover bgc:gray0
		&.origin bgc:gray0
	
	css .indicator inset:0 zi:100 t:auto h:3px b:-2px bgc:blue5/50 rd:full mx:2
		&.no-effect bgc:gray5/20


	hover = false

	<self
		.sortable-row
		.over=over
		.hover=hover
		.origin=dragOrigin
		draggable=yes
		@mouseenter=(hover = true)
		@mouseleave=(hover = false)
		@dragstart.emit('sortStart', {index, scrim})=(hover = false)
		@dragover.prevent=handleDragOver
		@dragenter.prevent=handleDragOver
		@dragend.emit('sortEnd')
		@drop.emit('sortDrop', {index, scrim})
	>
		<p.small[us:none pe:none max-width:100% overflow:hidden]>
			scrim.title 
			<span [c:gray5 fs:xs ml:2]> since
		<svg src="svg/trash.svg" [cursor:pointer us:none h:16px c:inherit @hover:pink7] @click.emit('remove', {index})>
		if over
			<div.indicator .no-effect=noEffect>
