import './rows'

tag any-card
	css cursor:default
		w:90vw @700:640px d:block

	css .header >>> $item
		o:1

	css .header.alert
		px:4 py:3 ta:center d:vflex ja:center
		bg:green5 c:white td:none
		@hover td:underline

	css $body
		ofy:auto max-height:50vh p:4 py:3
	
	css .list-head
		pos:sticky bg:white/90 fs:sm-/1 pt:5 c:gray6 pb:1 t:-3 fw:600 zi:1 d:none
	
	css .group-head fs:sm-/1 c:gray6 fw:500 mx:2

	css .heading fs:sm/1 c:gray6 fw:500 py:1
		d:hflex js:stretch
		@after ml:2 fl:1 as:center h:1px bg:currentColor content:" " o:0.3

	css .items
		counter-reset: item

	css .if-empty d:none
	css .items@empty + .if-empty d:block
	# css .items@empty + .items-if-empty 

	css .link
		cursor:pointer
		c:blue6 @hover:blue7 

	def list items = data.items
		<.items> for item in items
			<{item.$type}-row.inline.compact.numbered .selected=(item == data.focus) data=item context=data>

	<self>
		<{data.$type}-row.header data=data>
		<$body.js-scroll-container> list(data.items)

tag course-card < any-card

	def render
		<self>
			<course-row.header data=data>
			<$body.js-scroll-container> list(data.items)

tag module-card < any-card

	<self>
		<module-row.header data=data>
		<$body.js-scroll-container> list(data.items)

tag playlist-card < any-card

	<self>
		# <.head> <.title> data.title
		<playlist-row.header data=data>
		<$body.js-scroll-container> list(data.items)

tag scrim-card < any-card

	css w:90vw @600:480px
	css a.share p:1 4 rd:3px c:white c@hover:white td@hover:underline cursor:pointer

	css $sharefield
		w:100% py:3 px:3 rd:md bd:1px solid bc:gray4 fs:sm outline:none
		ff:inherit font-style:inherit fw:inherit
		@focus bxs:glow bc:blue4

	def createNote
		if data.branch
			data.branch.enter!
			data.branch.space!.createBranch!
			imba.commit!
		self

	def copy
		await persistIfLocal!
		$sharefield.select!
		document.execCommand('copy')
		imba.mount <app-snackbar data={message:"Shareable link copied"}>

	def persistIfLocal
		return unless data.branch.mine? and data.branch.local?

		const title = if data.$parent..gid != 'gjavascriptmas'
			data.branch.title!
		else
			const day = data.$parent.metadata..share..day or 'x'
			data.branch.title!.indexOf('Note at ') == -1 ? data.branch.title! :  "Solution for day {day} of #javascriptmas"
			

		await data.branch.persist!
		await api.post "/scrims/{data.branch.id!}",
			title: title
			access: 'guests'
			privacy: 'secret'

	def twitter
		let msg
		await persistIfLocal!
		self.url = "https://{window.location.hostname}{data.url}"

		if const course-share = data.$parent..course..metadata..share
			# If the course has a sharing format we iterate over array references `[arrayName]` 
			# and replace it with a random picked element from the corresponding array. 
			def pick-random(arr\Array) do arr[Math.floor(Math.random! * arr.length)]
			const t = msg = course-share.format
			const reg = /\[([\w]*)\]/g
			let match = reg.exec(t)
			while match != null
				let n = pick-random(course-share[match[1]])
				msg = msg.replace(match[0], n)
				match = reg.exec(t)

			# We also replace variable references `{varName}` with a self scoped variable.
			const vars = /\{([\w]*)\}/g
			let m = vars.exec(t)
			while m != null
				msg = msg.replace(m[0], self[m[1]])
				m = vars.exec(t)
		else
			msg = "Check out my code scrim {data.branch.title!} - {self.url}"

		let meta = data.$parent..metadata..share
		let twitter-url = "https://twitter.com/intent/tweet?text={window.encodeURIComponent(msg)}&related=@scrimba"

		if meta and meta['twitter-reply'] # seems $parent is not the parent locally. Is it in prod since it works there?
			twitter-url += "&url={meta['twitter-reply']}"

		window.open twitter-url, "_blank"

	get discord-msg-url
		store.settings["discord-msg-url-{data.id}"]
	set discord-msg-url val\string
		store.settings["discord-msg-url-{data.id}"] = val

	def discord
		return if discord-msg-url
		
		await persistIfLocal!
		let didShare = await api.post "/discord/share-scrim",
			id: data.id
			message: data.branch.title!

		discord-msg-url = didShare..url

		didShare ||= {
			code: 500
			message: "Failed to share. Check your Discord connection here."
			url: "https://{window.location.hostname}/settings"
			timeout: 10 * 1000
		}

		imba.mount <app-snackbar data=didShare>


	def render
		const items = data.children.filter do $1.note?

		<self>
			<scrim-row.header data=data>
			<$body.js-scroll-container>
				if items.length
					<.items> for item in items
						<scrim-row.inline.compact .selected=(item == data.focus) data=item>
				elif data.type == 'note'
					if !data.local?
						<div>
							css > p mb:2
							<p> "Share to:"
							<div[d:flex jc:flex-start]>
								css > * ml:0 mr:2
								<a.button[bgc:#1DA1F2].share @click=twitter> "Twitter"
								# if discord-msg-url
								# 	<a.button[bgc:#7289da].share href=discord-msg-url target="_blank"> "See in Discord"
								# else
								# 	<a.button[bgc:#7289da].share @click=discord> "Discord"
							<p[mt:4]> "Or click to copy this shareable link:"
							<input$sharefield type='text' value="https://scrimba.com{data.url}" @click=copy>
					else
						<div[c:gray6 fs:sm/1.2 p:2]> "You need to save your scrim to share it with others."
				else
					<div[c:gray6 fs:sm/1.2 p:2]> "You have no notes in this lesson. {<a.link.small @click=createNote> "Create one"}."

tag topic-card < any-card
	<self> <div[p:8 pt:6 c:gray6 fs:md/1.4]> data.description

tag home-card < any-card

	css course-row
		$prefix: ""

	set active bool
		if $active =? bool
			data

	def render
		<self>
			<$body.js-scroll-container>
				if store.user
					<div>
						<.heading> <span> "Your current courses"
						<.items[1rh:48px]>
							for item, i in data.attending when i < 3
								<course-row.inline .selected=(item == data.focus) data=item>
							if data..attending.length > 0
								<a.button.sm [mt:4 mb:4] href="/dashboard#enrolled"> "Go to my courses"
						
						<.if-empty> "You are currently not enrolled in any courses."

				else
					<div[c:gray6 p:6 d:block ta:center]> "{<a.link href="#sign-in"> "Log in"} to get quick access to enrolled courses and more."