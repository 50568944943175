import './dialog.imba'
import './course-tile'

tag no-github-education-modal < app-dialog
	css $box bgc:$white
	css 1w:min(90vw, 600px)
	css 1t:5vh

	def body
		<[of:hidden]>
			<header>
				<h3[ta:center]> "Unable to verify GitHub Education status"

			<p[mb:4]> "The Scrimba Student Pack is only available to current GitHub Education students. If you are a valid student please make sure you login with the correct user."
			<p> "1. Click { <a target='_blank' href="/github-education"> "here" } to open GitHub Education in a new tab."
			<p> "2. Click the logout button below."
			<p> "3. Close this tab and go to the tab you opened in step 1."
			<p> "4. Click the enroll button and login with the correct account."

			<a.button[my:8] href="/auth/logout"> "Logout"


