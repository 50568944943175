import './dialog'

tag change-subscription-modal < app-dialog
	prop mailSent = no
	prop sub
	prop discount-percent\number?
	prop discount-reference\string?
	css 1w:max(40%,340px)
	css 1t:5vh

	css button ws:normal

	get processor
		sub..processor
	
	def portal
		if processor == 'stripe'
			mailSent = yes
			let res = await api.post('/stripe/subscription/portal')
		else
			window.location = 'https://paypal.com'

	def reset
		mailSent = no
		close!

	def submit e
		return

	def body
		<>
			if mailSent
				<header> <h3[ta:center fs:lg]> "Secure link sent"
				<p[my:4]> "We have generated and sent a secure url for you to access your stripe settings to the email {store.user..email}."
				<button.button.sm @click=reset> "Close dialog"
			else
				<header[mb:3]>
					<h3[ta:center fs:lg]> "Change Subscription"
					if discount-percent and discount-reference
						<p> "Your plan is currently at a {<b> discount-percent + '% discount'}. If you unsubscribe, you will lose your discount, and you will risk having to pay the original list-price ({discount-reference}/month) if you ever want to re-activate your subscription again."
				if processor == 'stripe'
					<button[mb:4].button.sm @click=portal> "Manage stripe settings"
				elif processor == 'paypal'
					<a.button[mb:4].button.sm href="https://www.paypal.com/webapps/mpp/personal"> "Manage settings on paypal.com"
				elif processor == 'scrimba'
					<p> "You have a fixed length subscription. Please get in touch with support for changes to your subscription."
					<a.button[my:4].button.sm href="mailto:help@scrimba.com?subject=manual-subscription-{sub.id}"> "Mail support"
				unless sub..cancelled_at
					<p[o:0.5 ta:center]> "If you would like to cancel your subscription, please email help@scrimba.com."
					# <a[o:0.5 d:block ta:center mt:8] href="#cancel-subscription"> "I would like to cancel my subscription"
