import './dialog.imba'

# rename to scrim-modal at some and have it load any scrim after camapaign? 
tag teams-scrim-modal < app-dialog
	css 1w:min(90vw, 600px)
	css 1t:5vh
	css $box p:0 h:450px of:hidden

	def hydrate
		innerHTML = ''
		schedule!
		render!

	def body
		<iframe[w:100% h:450px] src="https://scrimba.com/scrim/coccb421586fbba068cc26aa1" frameborder=0>



